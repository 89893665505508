import React, { useState, useEffect } from 'react'
import useStore from 'state/knovStore'
import UserIcon from 'components/users/UserIcon'
import UserEditForm from './UserEditForm'
import CableApp from '../../actioncable'
import api from '../../api/api'
import cc from 'cryptocompare'
import cn from 'classnames'
import styles from './profile.module.scss'
import WithTooltip from 'components/shared/WithTooltip'
import Button from 'components/shared/buttons/Button'

export default function EditProfile(props) {
    const [user, setUser] = useState(window.gon.currentUser)
    const [balance, setBalance] = useState(null)
    const [potentialBsv, setPotentialBsv] = useState(props.potentialBsv)
    const [potentialUsd, setPotentialUsd] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [avatarQuery, setAvatarQuery] = useState(new Date().getTime())

    useEffect(function getUserSpaces() {
        api.getSpaces().then(spaces => {
            set({ userSpaces: spaces })
        })
    }, [])

    useEffect(() => {
        if (user && potentialBsv) {
            const getUsd = async () => {
                const price = await cc.price('bsv', 'usd')
                if (price && price['USD']) {
                    const bsv = (potentialBsv * price['USD']).toFixed(2)
                    setPotentialUsd(bsv)
                } else {
                    setPotentialUsd(0)
                }
            }
            getUsd()
        }
    }, [potentialBsv])

    useEffect(() => {
        if (user) {
            CableApp.channel = CableApp.cable.subscriptions.create(
                { channel: 'UserChannel', userId: user.id },
                {
                    received: async data => {
                        if (data.message === 'balance updated') {
                            setBalance(data.balance)
                            setIsLoading(false)
                            if (data.hasOwnProperty('potentialBsv')) {
                                setPotentialBsv(data.potentialBsv)
                            }
                        }
                    },
                    connected: api.requestBalance,
                },
            )
        }
        return () => {
            if (CableApp.channel && CableApp.channel.unsubscribe) {
                CableApp.channel.unsubscribe()
            }
        }
    }, [])

    const toggleEmailNoti = async e => {
        e.preventDefault()
        const email_noti = !(user.email_noti === true || user.email_noti === null)
        const data = await api.updateUser(user.id, {
            email_noti,
        })
        if (data.user) {
            setUser(data.user)
        } else {
            setErrors(data.errors)
        }
    }

    const emailNotiState = user.email_noti === true || user.email_noti === null ? 'On' : 'Off'
    const emailNotiBtn =
        user.email_noti === true || user.email_noti === null ? 'Turn Off' : 'Turn On'

    const showHandcash =
        gon.currentUser &&
        gon.currentUser.features &&
        gon.currentUser.features.some(feature => feature.name === 'handcash')

    const openUserImageUpdateModal = useStore(
        state => state.actions.modalActions.openUserImageUpdateModal,
    )

    return (
        <>
            {/* <UserProfileStats user={user} /> */}
            <div className={styles.userAvatarForm}>
                <div className={styles.header}>
                    <div
                        className={styles.userLogo}
                        onClick={() => {
                            openUserImageUpdateModal(user, newUser => {
                                setAvatarQuery(new Date().getTime())
                                setUser(newUser)
                            })
                        }}
                    >
                        <UserIcon user={user} avatarQuery={avatarQuery} />
                    </div>
                    <div className={styles.userName}> {user.name} </div>
                </div>
            </div>

            {showHandcash && (
                <>
                    {!props.withHandcash && potentialBsv > 0 && (
                        <div className={styles.integrationContainer}>
                            <div>{`Integrate Handcash to claim $${potentialUsd} of upvalue.`}</div>
                        </div>
                    )}

                    <div className={styles.integrationContainer}>
                        Your Handcash upvalue balance:{' '}
                        {isLoading ? <i className={'fa fa-spin fa-spinner'} /> : `$${balance}`}
                    </div>

                    <div className={styles.integrationContainer}>
                        <a id="connectButton" href={props.handcashRegistrationUrl}>
                            Connect with Handcash
                        </a>
                    </div>
                </>
            )}

            <div className={styles.emailNotiContainer}>
                <span className={styles.emailNotiLabel}>Instant Email Notifications:</span>

                <span className={styles.emailNotiState}>{emailNotiState}</span>

                <div className={styles.emailNotiBtnContainer}>
                    <WithTooltip tip="Get instant email notifications along with push notifications.">
                        <span className={cn(styles.userProfileBtn)} onClick={toggleEmailNoti}>
                            {`${emailNotiBtn}`}
                        </span>
                    </WithTooltip>
                </div>
            </div>

            <div className={styles.editUserFormContainer}>
                <UserEditForm user={user} setUser={setUser} />
            </div>

            <div className={cn(styles.twitterIntegrationContainer)}>
                <div className={styles.accountContainer}>
                    {user.twitter_providers &&
                        user.twitter_providers.map(twitter => {
                            return (
                                <div key={twitter.username} className={cn(styles.linkedProfile)}>
                                    <div className={styles.profile}>
                                        <div className={styles.image}>
                                            <img
                                                src={
                                                    twitter.image_url ||
                                                    'http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png'
                                                }
                                            ></img>
                                        </div>
                                        <div className={styles.username}>{twitter.username}</div>
                                    </div>
                                    <div className={styles.deleteAccount}>
                                        <i className="fa fa-close"></i>
                                    </div>
                                </div>
                            )
                        })}
                </div>

                {false && (
                    <div className={styles.twitterAuth}>
                        <a
                            href={`/auth/twitter?knov_user_id=${user.id}`}
                            className={cn(styles.twitterBtn, 'twitter-btn btn btn-primary')}
                        >
                            <i className="fa fa-twitter"></i>
                            Connect Twitter Account
                        </a>
                    </div>
                )}
            </div>

            <div className={styles.backButtonContainer}>
                <Button
                    type="default"
                    icon="fa fa-arrow-left"
                    text="Return to space"
                    onClick={
                        // TODO: do something better here
                        () => window.history.back()
                    }
                />
            </div>
        </>
    )
}
