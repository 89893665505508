import React from 'react'
import useStore from 'state/knovStore'
//import Question from '../questions/Question'

export default function ConfirmMerge(props) {
    const { containerQuest, embeddedQuest } = props.modalParams
    const mergeAnswers = useStore(state => state.mergeAnswers)

    return (
        <div className="confirm-merge-comp">
            MERGE ANSWERS FROM QUEST:
            <div className="embedded-quest-container">
                {/*<Question quest={embeddedQuest} showControls={false} hideVote />*/}
            </div>
            INTO QUEST:
            <div className="container-quest-container">
                {/*<Question quest={containerQuest} showControls={false} hideVote />*/}
            </div>
            <div className="merge-btn-container">
                <div
                    className="merge-btn btn-primary btn"
                    onClick={() => mergeAnswers(containerQuest.id, embeddedQuest.id)}
                >
                    Merge Quest
                </div>
            </div>
        </div>
    )
}
