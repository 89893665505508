import React, { useState } from 'react'
import { useEffect } from 'react'
import api from 'api/api'
import useStore from 'state/knovStore'
import styles from 'components/modals/wallet.module.scss'
import Button from 'components/shared/buttons/Button'
import { toBitcoin, toSats } from 'lib/bsv-util'
import cn from 'classnames'
import { useTxChannel } from 'state/channels/useUserChannel'
import { BsvWalletType, PandaWallet, SHUAllet } from 'wallets/wallets'

export default function Wallet({ closeModal }) {
    const currentUserBsvBalance = useStore(state => state.currentUserBsvBalance)
    const currentUserBsvAddress = useStore(state => state.currentUserBsvAddress)
    const currentUserBsvWallet = useStore(state => state.currentUserBsvWallet)
    const set = useStore.getState().set

    const [amountSats, setAmountSats] = useState<number | ''>('')
    const [address, setAddress] = useState('')
    const [sending, setSending] = useState(false)
    // to fund panda the funds need to be sent to what panda refers to as the wallet's `bsvAddress`, but
    // locks and other parts of the app that look at `currentUserBsvAddress` still need to use what panda
    // refers to as the wallets `identityAddress`, so we override `currentUserBsvAddress` in this case
    const [pandaDisplayAddress, setPandaDisplayAddress] = useState('')

    useEffect(() => {
        async function walletInstantiator() {
            const wallet = currentUserBsvWallet
            await wallet?.authenticate?.()
            if (wallet?.type() === BsvWalletType.PANDA) {
                setPandaDisplayAddress((await panda.getAddresses())?.bsvAddress)
            }
        }
        walletInstantiator()
    }, [currentUserBsvWallet])

    useTxChannel({
        successCallback: txId => {
            setSending(false)
            alert(`Transaction ${txId} sent.`)
        },
        errorCallback: errorMessage => {
            setSending(false)
            alert(`Error sending transaction: ${errorMessage}`)
        },
    })

    return (
        <div>
            <div className={styles.balanceContainer}>
                <i className="fa fa-btc" />
                {toBitcoin(currentUserBsvBalance)}
            </div>

            <div className={styles.walletChoiceContainer}>
                <div
                    className={cn(styles.walletChoiceBtn, styles.pandaWallet, {
                        [styles.activeWallet]: currentUserBsvWallet?.type() === BsvWalletType.PANDA,
                    })}
                    onClick={async e => {
                        const wallet = new PandaWallet()
                        // this strange null + settimeout (next tick) dance is needed otherwise zustand doesn't rerender
                        set({ currentUserBsvWallet: null })
                        setTimeout(() => set({ currentUserBsvWallet: wallet }), 0)
                        api.updateUserOptions({
                            bsv_wallet_type: BsvWalletType.PANDA,
                        })
                        api.setPandaPubkey(
                            gon.currentUser.id,
                            (await panda.getPubKeys())?.identityPubKey,
                        )
                    }}
                >
                    <img
                        src="/pandawalletlogo.png"
                        alt="Panda Wallet Logo"
                        className={styles.walletLogo}
                    />
                    <div className={styles.walletName}>Panda Wallet</div>
                </div>

                <div
                    className={cn(styles.walletChoiceBtn, styles.shualletWallet, {
                        [styles.activeWallet]:
                            currentUserBsvWallet?.type() === BsvWalletType.SHUALLET,
                    })}
                    onClick={e => {
                        const wallet = new SHUAllet()
                        // this strange null + settimeout (next tick) dance is needed otherwise zustand doesn't rerender
                        set({ currentUserBsvWallet: null })
                        setTimeout(() => set({ currentUserBsvWallet: wallet }), 0)
                        api.updateUserOptions({
                            bsv_wallet_type: BsvWalletType.SHUALLET,
                        })
                    }}
                >
                    <img src="/SHUAllet.png" alt="SHUAllet Logo" className={styles.walletLogo} />
                    <div className={styles.walletName}>SHUAllet</div>
                </div>
            </div>

            <div className={styles.depositContainer}>
                <div className={styles.depositHeader}>Receive</div>

                <div>
                    Address:{' '}
                    {currentUserBsvWallet?.type() === BsvWalletType.PANDA
                        ? pandaDisplayAddress
                        : currentUserBsvAddress}
                </div>
            </div>

            {currentUserBsvWallet?.type() !== BsvWalletType.PANDA && (
                <>
                    <div className={styles.withdrawContainer}>
                        <div className={styles.withdrawHeader}>Send</div>

                        <div className={styles.addressControl}>
                            <div className={styles.inputLabel}>Destination Address</div>
                            <div className={styles.inputContainer}>
                                <input
                                    className={cn(styles.input, 'wallet-input')}
                                    type="text"
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                    placeholder="Enter destination address."
                                />
                            </div>
                        </div>

                        <div className={styles.amountControl}>
                            <div className={styles.inputLabel}>Amount (bitcoin)</div>
                            <div className={styles.inputContainer}>
                                <input
                                    className={cn(styles.input, 'wallet-input')}
                                    type="number"
                                    value={
                                        typeof amountSats === 'number' ? toBitcoin(amountSats) : ''
                                    }
                                    onChange={ev => {
                                        setAmountSats(
                                            ev.target.value ? toSats(Number(ev.target.value)) : '',
                                        )
                                    }}
                                    placeholder="Enter amount in bitcoin."
                                />
                            </div>
                        </div>

                        <div className={styles.sendBtnContainer}>
                            {currentUserBsvWallet?.type() === BsvWalletType.SHUALLET && (
                                <a
                                    href="#"
                                    onClick={async e => {
                                        e.preventDefault()
                                        try {
                                            const blob = await api.downloadBsvWallet()
                                            const url = window.URL.createObjectURL(blob)
                                            const link = document.createElement('a')
                                            link.href = url
                                            link.setAttribute('download', 'treechat_shuallet.json')
                                            document.body.appendChild(link)
                                            link.click()
                                            link.parentNode.removeChild(link)
                                        } catch (error) {
                                            console.error('Error downloading wallet:', error)
                                            alert('Wallet not found or error occurred.')
                                        }
                                    }}
                                >
                                    Download Shuallet
                                </a>
                            )}

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    disabled={!amountSats || !address}
                                    inProgress={sending}
                                    onClick={async () => {
                                        if (
                                            amountSats &&
                                            currentUserBsvBalance >= Number(amountSats)
                                        ) {
                                            setSending(true)
                                            currentUserBsvWallet.sendBsv(address, amountSats)
                                        } else alert('Insufficient funds.')
                                    }}
                                    text="Send"
                                    contextStyles={styles.sendBtn}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
