import React, { useState, useEffect } from 'react'
import api from 'api/api'
import perms from 'lib/perms'
import Edit from 'components/answers/AnswerControls/Edit'
import CopyLinkButton from 'components/shared/CopyLinkButton'
import WithTooltip from 'components/shared/WithTooltip'
import styles from 'components/answers/answer-controls.module.scss'
import cn from 'classnames'
import * as cache from 'state/cache'
import useAnswerControls from 'components/answers/useAnswerControls'

import { isMobile } from 'react-device-detect'

export default function AnswerControls(props) {
    const answer = props.answer
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(props.deleteConfirm)
    const [showArchiveConfirm, setShowArchiveConfirm] = useState(props.archiveConfirm)

    const myRef = React.useRef(null)

    useEffect(() => {
        if (!props.popup) return

        function handleClickOutside(ev) {
            if (myRef.current && !myRef.current.contains(ev.target)) {
                ev.stopPropagation()
                ev.preventDefault()
                setTimeout(() => {
                    props.toggleControlsPopup()
                }, 100)
            }
        }

        // Bind the event listener
        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [myRef])

    const { showControlButtons, showCopyLink } = useAnswerControls(props)

    const deleteHandler = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        const answerId = props.answer.id
        props.removeAnswer(answerId)
        api.deleteAnswer(answerId)
    }

    const archiveHandler = ev => {
        ev.preventDefault()
        ev.stopPropagation()
        const answer = props.answer
        answer.archive ? logEv('UNARCHIVE_ANSWER') : logEv('ARCHIVE_ANSWER')

        const archive = answer.archive
        api.updateAnswer(answer.id, { archive: !archive })
        toggleArchiveConfirm()
        const newAnswer = {
            ...answer,
            updated_at: new Date(),
            archive: !archive,
        }
        cache.cacheAnswer(newAnswer)
    }

    const toggleEdit = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        return props.toggleEdit()
    }

    const savePost = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        return props.savePost()
    }

    const isUserSignedIn = () => !!gon.currentUser
    const isOwner = () => gon.currentUser && gon.currentUser.id === props.answer.user_id

    const showConfirm = () => showDeleteConfirm || showArchiveConfirm

    const showEdit = () => {
        if (props.popup) return false

        return (
            !showConfirm() &&
            isUserSignedIn() &&
            isOwner() &&
            (props.editMode || showControlButtons()) &&
            !props.answer.tweet
        )
    }

    const showTweet = () => !showConfirm() && !!props.answer.tweet && showControlButtons()

    const showDelete = () =>
        perms.canDeleteAnswer(gon.currentUser, answer) &&
        !showDeleteConfirm &&
        !showArchiveConfirm &&
        showControlButtons() &&
        !props.editMode

    const toggleDeleteConfirm = ev => {
        if (props.toggleDeleteConfirm) props.toggleDeleteConfirm()
        else {
            ev?.preventDefault()
            ev?.stopPropagation()

            setShowDeleteConfirm(!showDeleteConfirm)
            setShowArchiveConfirm(false)
        }
    }

    const showArchive = () => {
        const perm = gon.currentUser && perms.canArchiveAnswer(gon.currentUser, props.quest)
        const uiPerm =
            !showArchiveConfirm && !showDeleteConfirm && showControlButtons() && !props.editMode

        return uiPerm && perm
    }

    const toggleArchiveConfirm = ev => {
        if (props.toggleArchiveConfirm) props.toggleArchiveConfirm()
        else {
            ev?.preventDefault()
            ev?.stopPropagation()

            setShowArchiveConfirm(!showArchiveConfirm)
            setShowDeleteConfirm(false)
        }
    }

    return (
        <div ref={myRef} className={cn(styles.answerControlsComp, 'answer-controls-comp')}>
            {showTweet() && (
                <div className={cn(styles.answerControl, 'tweeted-btn pull-left')}>
                    <div
                        className="tweeted-button-comp"
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        <WithTooltip tip="Tweeted message." offset={0}>
                            <div className={cn('answer-btn', styles.twitterLabel)}>
                                <i className={'fa fa-twitter'}></i>
                            </div>
                        </WithTooltip>
                        <span className="control-label">This message was tweeted</span>
                    </div>
                </div>
            )}

            {showEdit() && (
                <Edit
                    quest={props.quest}
                    answer={props.answer}
                    answerHover={props.answerHover}
                    saving={props.saving}
                    editMode={props.editMode}
                    toggleEdit={toggleEdit}
                    savePost={savePost}
                    setImageDownload={props.setImageDownload}
                    content={props.content}
                    imgHandler={props.imgHandler}
                    fileHandler={props.fileHandler}
                    videoHandler={props.videoHandler}
                    mobileVideoHandler={props.mobileVideoHandler}
                    clearEdits={props.clearEdits}
                />
            )}

            {showArchive() && (
                <ArchiveButton
                    archived={answer.archive}
                    toggleArchiveConfirm={toggleArchiveConfirm}
                    popup={props.popup}
                />
            )}

            {showArchiveConfirm && (
                <ConfirmArchive
                    archived={answer?.archive}
                    toggleArchiveConfirm={toggleArchiveConfirm}
                    handleArchive={archiveHandler}
                />
            )}

            {false && showCopyLink() && (
                <CopyLinkButton
                    tooltip
                    getPublicLink={props.getPublicLink}
                    isMobile={isMobile}
                    styles={styles}
                    showLabel={true}
                />
            )}

            {showDelete() && (
                <DeleteButton toggleDeleteConfirm={toggleDeleteConfirm} popup={props.popup} />
            )}

            {showDeleteConfirm && (
                <ConfirmDelete
                    toggleDeleteConfirm={toggleDeleteConfirm}
                    deleteHandler={deleteHandler}
                />
            )}
        </div>
    )
}

function ArchiveButton(props) {
    const archived = props.archived
    const iconClass = archived ? 'fa fa-folder-o' : 'fa fa-folder'
    const label = archived ? 'unarchive' : 'archive'
    const tip = archived ? 'Unarchive' : 'Archive'

    const mobileAnswerBtn = isMobile ? styles.mobileAnswerBtn : null
    const popupStyles = props.popup ? styles.popup : null

    return (
        <div
            className={cn(styles.answerControl, styles.archiveButtonComp, popupStyles)}
            onClick={props.toggleArchiveConfirm}
        >
            <WithTooltip tip={tip} offset={0}>
                <div className={cn(styles.answerBtn, mobileAnswerBtn)}>
                    <i className={iconClass}></i>

                    <span className={cn('control-label', styles.controlLabel)}>{label}</span>
                </div>
            </WithTooltip>
        </div>
    )
}

function DeleteButton(props) {
    const mobileAnswerBtn = isMobile ? styles.mobileAnswerBtn : null
    const popupStyles = props.popup ? styles.popup : null

    return (
        <div className={cn(styles.answerControl, styles.deleteButtonComp, popupStyles)}>
            <WithTooltip tip="Delete." offset={0}>
                <div
                    className={cn(styles.answerBtn, mobileAnswerBtn)}
                    onClick={props.toggleDeleteConfirm}
                >
                    <i className="fa fa-close" />

                    <span className={cn('control-label', styles.controlLabel)}>delete</span>
                </div>
            </WithTooltip>
        </div>
    )
}

function ConfirmArchive(props) {
    const archived = props.archived
    const toggleArchiveConfirm = props.toggleArchiveConfirm
    const archiveHandler = props.handleArchive

    return (
        <div className={styles.confirmComp}>
            <div className={styles.cancelBtn} onClick={toggleArchiveConfirm}>
                <i className="fa fa-close" />

                <span
                    className={cn(
                        styles.controlLabel,
                        styles.confirmLabel,
                        'control-label confirm-label',
                    )}
                >
                    cancel
                </span>
            </div>

            <div className="archive-confirm-btn pull-right" onClick={archiveHandler}>
                {archived ? <i className="fa fa-folder-o" /> : <i className="fa fa-folder" />}
                {archived ? (
                    <span className="archive-confirm-label confirm-label">unarchive</span>
                ) : (
                    <span className="archive-confirm-label confirm-label">archive</span>
                )}
            </div>
        </div>
    )
}

function ConfirmDelete(props) {
    const toggleDeleteConfirm = props.toggleDeleteConfirm
    const deleteHandler = props.deleteHandler

    return (
        <div className={styles.confirmComp}>
            <div className={styles.cancelBtn} onClick={toggleDeleteConfirm}>
                <i className="fa fa-close" />

                <span
                    className={cn(
                        styles.controlLabel,
                        styles.confirmLabel,
                        'control-label confirm-label',
                    )}
                >
                    cancel
                </span>
            </div>

            <div className="delete-confirm-btn" onClick={deleteHandler}>
                <i className="fa fa-close" />
                <span className="delete-confirm-label confirm-label">delete</span>
            </div>
        </div>
    )
}
