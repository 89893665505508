import React from 'react'
import satsIcon from 'assets/sats.svg'

export default function SatsIcon({
    color = '#ffab46',
    width = 12,
    height = 15,
    strokeWidth = 6,
    lineWidth = 12,
    contextStyles,
}) {
    return (
        <div className={contextStyles}>
            <svg width="61" height="124" viewBox="0 0 61 124" xmlns="http://www.w3.org/2000/svg">
                <path d="M60.408 82.656C60.408 88.032 59.0853 92.7253 56.44 96.736C53.7947 100.747 49.9547 103.861 44.92 106.08C39.9707 108.213 33.912 109.28 26.744 109.28C23.5867 109.28 20.472 109.067 17.4 108.64C14.4133 108.213 11.512 107.616 8.696 106.848C5.96533 105.995 3.36267 104.971 0.888 103.776V85.728C5.24 87.6053 9.72 89.3547 14.328 90.976C19.0213 92.512 23.672 93.28 28.28 93.28C31.4373 93.28 33.9547 92.8533 35.832 92C37.7947 91.1467 39.2027 89.9947 40.056 88.544C40.9947 87.0933 41.464 85.4293 41.464 83.552C41.464 81.248 40.6533 79.2853 39.032 77.664C37.496 76.0427 35.4053 74.5493 32.76 73.184C30.1147 71.7333 27.0853 70.1973 23.672 68.576C21.5387 67.552 19.2347 66.3573 16.76 64.992C14.2853 63.5413 11.896 61.792 9.592 59.744C7.37333 57.6107 5.53867 55.0507 4.088 52.064C2.63733 49.0773 1.912 45.4933 1.912 41.312C1.912 35.8507 3.14933 31.2 5.624 27.36C8.184 23.4347 11.768 20.448 16.376 18.4C21.0693 16.352 26.5733 15.328 32.888 15.328C37.6667 15.328 42.1893 15.8827 46.456 16.992C50.808 18.1013 55.3307 19.68 60.024 21.728L53.752 36.832C49.5707 35.1253 45.816 33.8027 42.488 32.864C39.16 31.9253 35.7467 31.456 32.248 31.456C29.8587 31.456 27.8107 31.84 26.104 32.608C24.3973 33.376 23.0747 34.4427 22.136 35.808C21.2827 37.1733 20.856 38.7947 20.856 40.672C20.856 42.8053 21.496 44.64 22.776 46.176C24.056 47.6267 25.976 49.0347 28.536 50.4C31.096 51.7653 34.296 53.3867 38.136 55.264C42.8293 57.4827 46.7973 59.7867 50.04 62.176C53.368 64.5653 55.928 67.3813 57.72 70.624C59.512 73.8667 60.408 77.8773 60.408 82.656Z" />
                <line x1="31" x2="31" y2="16" strokeWidth="14" />
                <line x1="31" y1="108" x2="31" y2="124" strokeWidth="14" />
            </svg>
        </div>
    )
}
