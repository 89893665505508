import react, { useState } from 'react'

export default function useAnswerControls(props) {
    const showControlButtons = () => props.showControlButtons

    const showCopyLink = () => {
        return (
            showControlButtons() &&
            !props.editMode &&
            !isSystemUser() &&
            !showArchiveConfirm &&
            !showDeleteConfirm &&
            !props.systemQuoteNoti
        )
    }

    return {
        showControlButtons,
        showCopyLink,
    }
}
