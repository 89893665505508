import React, { useEffect, useState, useRef, useLayoutEffect, useContext } from 'react'
import useStore from 'state/knovStore'
import useQuestChannel from 'state/channels/useQuestChannel'

import Quest from 'components/quests/Quest'
import 'api/api'
import SearchHeader from 'components/panels/SearchHeader'
import styles from 'components/panels/panel.module.scss'
import { isMobile } from 'react-device-detect'
import QuestInView from 'components/quests/QuestInView'
import PanelHeader from 'components/panels/PanelHeader'
import useGetQuest from 'refactor/hooks/api/useGetQuest'
import useGetQuests, { serializeFilterKey } from 'refactor/hooks/api/useGetQuests'
import cn from 'classnames'
import { isPanelLeft, isPanelCenter, isPanelRight } from 'state/imperativeApis/swiperApi'
import usePanelContext from 'refactor/hooks/usePanelContext'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import ThinQuest from 'components/quests/ThinQuest'
import People from 'components/people/People'
import useLogRender from 'refactor/hooks/useLogRender'

export default React.memo(function Panel(props) {
    const panelContext = usePanelContext()
    // Add any other contexts you're using
    // const someOtherContext = useContext(SomeOtherContext)

    useLogRender(props, {
        panelContext,
    })

    const { panel, hide } = panelContext

    const panelId = panel.panelId
    const filter = panel.filter

    useEffect(function onMount() {
        function onHashChange() {
            if (isPanelCenter(panelId) && location.hash === '#play') {
                openStoryModal(quest)
            }
        }
        window.onhashchange = onHashChange
        return () => {
            window.removeEventListener('hashchange', onHashChange)
        }
    }, [])

    const openStoryModal = useStore.getState().actions.modalActions.openStoryModal

    const isQuest = filter?.questId
    const isQuestStream = !isQuest

    console.log('RENDER PANEL', panelId, panel.empty, filter)

    return (
        <ErrorBoundary label={`panel ${panel.panelId}`}>
            <div
                style={{ visibility: hide ? 'hidden' : 'visible' }}
                className={cn(
                    styles.panelComp,
                    props.keyboardOpen && styles.keyboardOpen,
                    'panel-comp new-quest quests-new-view',
                )}
            >
                <div className={cn(styles.panelHeaderContainer)}>
                    <PanelHeader />
                </div>

                {isQuestStream && <QuestStream filter={filter} />}

                {isQuest && (
                    <div className={cn(styles.activeQuestContainer, `panel-quest quest-container`)}>
                        <QuestContainer filter={filter} />
                    </div>
                )}
            </div>

            {/* We use this instead of padding to handle mobile keyboard wierdenss. */}
            {!props.keyboardOpen && <div className={styles.keyboardPlaceholder} />}
        </ErrorBoundary>
    )
})

function Loading() {
    return (
        <div className={styles.loader}>
            <div className={cn(styles.loadingIcon)}>
                <i className="fa fa-circle-o-notch fa-spin fa-lg" />
            </div>
        </div>
    )
}

function QuestContainer({ filter }) {
    const { panel, scrollContainerRef, animationPanel } = usePanelContext()
    const panelId = panel?.panelId
    const questId = filter?.questId
    const { quest, isFetching } = useGetQuest(questId)
    useQuestChannel(questId)

    const newAnswerRef = useRef(null)

    useEffect(function onMount() {
        ;(async () => {
            const isOnScreen = isMobile
                ? isPanelCenter(panelId)
                : isPanelLeft(panelId) || isPanelCenter(panelId) || isPanelRight(panelId)
            if (!animationPanel && isOnScreen && !quest?.is_draft) {
                // If Quest mounts on screen, this counts as a view.
                await api.userViewsQuest(filter.questId)
                const actionsQueryKey = ['actions']
                queryClient.invalidateQueries({ queryKey: actionsQueryKey })
                queryClient.refetchQueries({ queryKey: actionsQueryKey }, { exact: true })
            }
        })()
        logEv('QUEST VIEW', { questId })
    }, [])

    const ANSWER_HEIGHT = isMobile ? 140 : 125
    const SCROLL_OFFSET = 1500

    useEffect(
        function onQuestScroll() {
            if (
                !animationPanel &&
                quest?.sorted_answers &&
                useStore.getState().panels.lastAction?.insert?.panelId === panelId
            ) {
                const answerId = panel?.filter?.answerId
                const scrollContainer = scrollContainerRef.current
                const answerElement = answerId
                    ? scrollContainer.querySelector(`.answer-in-view-${answerId}`)
                    : null

                if (answerElement) {
                    const answerIndex = quest.sorted_answers.findIndex(
                        answer => answer.id === answerId,
                    )
                    const scrollHeight = answerIndex * ANSWER_HEIGHT
                    scrollContainer.scrollTop = Math.max(0, scrollHeight - SCROLL_OFFSET)

                    requestAnimationFrame(() => {
                        answerElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    })
                } else {
                    // Instant scroll.
                    const scrollHeight = scrollContainer.scrollHeight
                    console.log('scroll into view', scrollHeight - SCROLL_OFFSET, scrollHeight)
                    scrollContainer.scrollTop = Math.max(0, scrollHeight - SCROLL_OFFSET)
                    // Smooth scroll for the rest.
                    requestAnimationFrame(() => {
                        scrollContainer.scrollTo({ top: scrollHeight, behavior: 'smooth' })
                        setTimeout(() => {
                            newAnswerRef.current?.focusEditor()
                        }, 500)
                    })
                }
            }
        },
        [quest?.id], // We use quest id here to make sure to run only on initial load.
    )

    return isFetching ? (
        <Loading />
    ) : (
        <Quest
            panelId={panelId}
            panel={panel}
            isActive={true} // Means this is a single quest view.
            showAll={true}
            quest={quest}
            shouldPlay={window.location.href.endsWith('#play')}
            filter={filter}
            query={filter?.query}
            ix={0}
            newAnswerRef={newAnswerRef}
        />
    )
}

function QuestStream({ filter }) {
    const { panel, scrollContainerRef, animationPanel } = usePanelContext()
    const panelId = panel.panelId
    const params = filter?.notifications ? { clear: true } : {}
    const [isRefreshing, setIsRefreshing] = useState(false)

    useEffect(function onMount() {
        if (animationPanel) return

        // Invalidate and refetch quests when the panel mounts
        const filterKey = serializeFilterKey(filter)
        queryClient.refetchQueries({ queryKey: ['quests', filterKey], exact: true })
        // Clear numNoti when notification panel loads.
        if (filter?.notifications) useStore.getState().set({ numNoti: 0 })
        logEv('STREAM VIEW', { filter })
    }, [])

    const { quests, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useGetQuests(
        filter,
        { enabled: true, params },
    )

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current

        const onScroll = () => {
            if (scrollContainer) {
                const { scrollTop, scrollHeight, clientHeight } = scrollContainer
                if (
                    scrollTop + clientHeight >= scrollHeight - 500 &&
                    hasNextPage &&
                    !isFetchingNextPage
                ) {
                    fetchNextPage()
                }
            }
        }

        scrollContainer?.addEventListener('scroll', onScroll)

        return () => {
            scrollContainer?.removeEventListener('scroll', onScroll)
        }
    }, [hasNextPage, isFetchingNextPage])

    const isStarred = filter.starred
    const isPeople = filter.people
    const isThinQuest = isStarred || isPeople

    return (
        <div className={styles.questStreamComp}>
            <div
                className={cn(
                    isThinQuest ? styles.thinSearchHeaderContainer : styles.searchHeaderContainer,
                )}
            >
                <SearchHeader
                    filter={filter}
                    setIsRefreshing={setIsRefreshing}
                    quest={quests?.[0]}
                />
            </div>

            {isFetching && (!quests.length || isRefreshing) ? (
                <Loading />
            ) : (
                <ErrorBoundary label={`QuestList with filter ${JSON.stringify(filter)}`}>
                    <div className={cn(styles.questList, 'quest-list')}>
                        {quests?.map((quest, ix) => {
                            const questId = quest?.id
                            if (!questId) return null

                            return (
                                <QuestInView key={questId} questId={questId}>
                                    <div
                                        key={`${quest.id}`}
                                        className={cn(
                                            isThinQuest
                                                ? styles.thinQuestContainer
                                                : styles.questContainer,
                                            ix === quests.length - 1 && styles.lastQuestContainer,
                                            'quest-container',
                                        )}
                                    >
                                        {isPeople ? (
                                            <People
                                                quest={quest}
                                                contextStyles={styles.peopleQuest}
                                            />
                                        ) : isStarred ? (
                                            <ThinQuest
                                                quest={quest}
                                                contextStyles={styles.thinQuest}
                                                showStream={true}
                                            />
                                        ) : (
                                            <Quest
                                                panelId={panelId}
                                                panel={panel}
                                                quest={quest}
                                                filter={filter}
                                                query={filter?.query}
                                                ix={ix}
                                            />
                                        )}
                                    </div>
                                </QuestInView>
                            )
                        })}
                    </div>
                </ErrorBoundary>
            )}
        </div>
    )
}
