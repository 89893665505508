import { Quest } from 'types/quests'
import { Answer } from 'types/answers'
import queryClient from 'api/queryClient'

function set(key, data) {
    queryClient.setQueryData(key, data)
}

export function getCachedAnswer(answerId: string): Answer {
    const answer = queryClient.getQueryData(['answer', answerId]) as Answer
    return answer
        ? {
              ...answer,
              ...(answer.embeds && {
                  embeds: answer.embeds.map(embed =>
                      queryClient.getQueryData(['answer', embed.id]),
                  ),
              }),
              ...(answer.draft_answer_id && {
                  draft_answer: queryClient.getQueryData(['answer', answer.draft_answer_id]),
              }),
          }
        : null
}

export function cacheAnswers(answers: Answer[]) {
    answers.map(answer => cacheAnswer(answer))
}

export function cacheAnswer(answer: Answer) {
    if (!answer) return null

    answer.embeds?.forEach(embed => {
        if (!queryClient.getQueryData(['answer', embed.id])) {
            set(['answer', embed.id], embed)
        }
    })

    set(['answer', answer.id], {
        ...answer,
        ...(answer.embeds && { embeds: answer.embeds.map(embed => ({ id: embed.id })) }),
        ...(answer.draft_answer?.id && { draft_answer_id: answer.draft_answer.id }),
    })
    return answer
}

export function getCachedQuest(questId: string): Quest {
    const quest = queryClient.getQueryData(['quest', questId]) as Quest
    if (quest)
        return {
            ...quest,
            ...(quest.parent_id && { parent: getCachedAnswer(quest.parent_id) }),
            ...(quest.new_answer_id && { new_answer: getCachedAnswer(quest.new_answer_id) }),
            ...(quest.sorted_answers && {
                sorted_answers: quest.sorted_answers.map(answer => {
                    return getCachedAnswer(answer.id)
                }),
            }),
        }
    return null
}

export function cacheQuests(quests) {
    return quests.map(quest => cacheQuest(quest))
}

export function cacheQuest(quest: Quest) {
    if (!quest) return null

    // Normalize in cache.
    if (quest.parent) {
        cacheAnswer(quest.parent)
    }

    if (quest.new_answer) {
        cacheAnswer(quest.new_answer)
    }

    quest.sorted_answers.forEach(answer => {
        cacheAnswer(answer)
    })

    set(['quest', quest.id], {
        ...quest,
        parent_id: quest.parent?.id,
        parent: { id: quest.parent?.id },
        sorted_answers: quest.sorted_answers?.map(answer => ({ id: answer.id })),
        ...(quest.new_answer?.id && { new_answer: { id: quest.new_answer.id } }),
        ...(quest.new_answer?.id && { new_answer_id: quest.new_answer.id }),
    })
    return quest
}

export function updateCachedAnswer(answerId, params) {
    let answer = getCachedAnswer(answerId)
    answer.updated_at = new Date()

    Object.entries(params).forEach(([key, val]) => {
        if (['quest_id', 'side_quest_id'].includes(key)) return

        answer[key] = val
    })

    if (params?.side_quest_id !== undefined) {
        const newSideQuest = getCachedQuest(params.side_quest_id)
        if (newSideQuest) {
            answer.child_quests = [newSideQuest]
            answer.child_answer_count = newSideQuest?.sorted_answers?.length

            updateCachedQuest(newSideQuest?.id, {
                parent_id: answer.id,
                parent: answer,
            })
        } else {
            answer.child_quests = []
            answer.child_answer_count = 0
        }
    }

    if (answer && params?.quest_id !== undefined) {
        const origQuestId = answer.quest_id
        const newQuestId = params?.quest_id

        const origQuest = getCachedQuest(origQuestId)
        const newQuest = getCachedQuest(newQuestId)

        answer = { ...answer, quest_id: newQuestId }

        // remove this answer from old quest's sorted answers
        if (origQuest) {
            updateCachedQuest(origQuestId, {
                sorted_answers: origQuest.sorted_answers.filter(a => a?.id != answerId),
            })
        }
        // add it to new quest's sorted answers, position will get updated
        // in cacheQuest()
        if (newQuest) {
            updateCachedQuest(newQuestId, {
                sorted_answers: [...newQuest.sorted_answers, answer],
            })
        }
    }

    return cacheAnswer(answer)
}

export function updateCachedQuest(questId, newContent) {
    return cacheQuest({ ...getCachedQuest(questId), ...newContent })
}
