import useStore from 'state/knovStore'
import api from 'api/api'

export enum BsvWalletType {
    SHUALLET = 'SHUAllet',
    PANDA = 'panda',
    // RELAYX = 'relayx'
}

export interface IWalletInterface {
    authenticate(): Promise<void>
    type(): BsvWalletType
    /**
     * asynchronously retrieves the wallet's balance, does not return the balance itself.
     * balance can be retrieved from zustand store's currentUserBsvBalance.
     */
    getBalance(): Promise<void>
    /**
     * asynchronously retrieves the wallet's address, does not return the adsdress itself.
     * address can be retrieved from zustand store's currentUserBsvAddress
     */
    getAddress(): Promise<void>
    getPaymail(): Promise<string>
    sendBsv(address: string, amountSats: number): Promise<string>
    broadcastTx(
        rawTx: string,
        fund?: boolean,
        walletAddress?: string,
        privateKeyWIF?: string,
    ): Promise<string>
}

class WalletBase implements IWalletInterface {
    constructor() {
        this.authenticate()
    }
    async authenticate(): Promise<void> {
        throw new Error('Method not implemented.')
    }
    type(): BsvWalletType {
        throw new Error('Method not implemented.')
    }
    async getBalance(): Promise<void> {
        throw new Error('Method not implemented.')
    }
    async getAddress(): Promise<void> {
        throw new Error('Method not implemented.')
    }
    async getPaymail(): Promise<string> {
        throw new Error('Method not implemented.')
    }
    async sendBsv(address: string, amountSats: number): Promise<string> {
        throw new Error('Method not implemented.')
    }
    async broadcastTx(
        rawTx: string,
        fund?: boolean,
        walletAddress?: string,
        privateKeyWIF?: string,
    ): Promise<string> {
        throw new Error('Method not implemented.')
    }
}
export class SHUAllet extends WalletBase {
    constructor() {
        super()
    }
    type(): BsvWalletType {
        return BsvWalletType.SHUALLET
    }
    async authenticate(): Promise<void> {
        // We go through rails api instead of bsv node service directly so as not to expose keys.
        await api.ensureBsvWallet()
        await this.getBalance()
        await this.getAddress()
    }

    async getBalance(): Promise<void> {
        await api.getBsvBalance()
    }

    async getAddress(): Promise<void> {
        await api.getBsvAddress()
    }

    async getPaymail(): Promise<string> {
        // shuallet doesn't actually have paymails, so just return address for now
        return useStore?.getState()?.currentUserBsvAddress
    }

    async sendBsv(address: string, amountSats: number): Promise<string> {
        // Use sendBsv function from api.ts
        return api.sendBsv(address, amountSats)
    }

    async broadcastTx(rawTx: string, fund: boolean): Promise<string> {
        const res = await api.broadcastBsvTx(rawTx, fund)
        const txid = res?.tx_id
        if (!txid) {
            throw new Error('Failed to broadcast transaction: No transaction ID returned.')
        }
        return txid
    }
}
export class PandaWallet extends WalletBase {
    constructor() {
        super()
    }

    type(): BsvWalletType {
        return BsvWalletType.PANDA
    }

    async authenticate(): Promise<void> {
        const panda = await this._getPanda()
        useStore.getState().set({
            currentUserBsvWallet: this,
        })
        await this.getBalance()
        await this.getAddress()
    }

    async broadcastTx(
        rawTx: string,
        fund?: boolean,
        walletAddress?: string,
        privateKeyWIF?: string,
    ): Promise<string> {
        // not implemented for panda wallet yet
        const panda = await this._getPanda()
        try {
            // broadcast transaction using panda wallet
            console.log('panda broadcasting:', { rawTx, fund })
            const txId = await panda.broadcast({ rawtx: rawTx, fund })
            console.log('panda broadcasted:', { rawTx, fund })
            return txId
        } catch (error) {
            console.error('Failed to broadcast transaction with Panda Wallet', error, error.stack)
            throw new Error('Failed to broadcast transaction with Panda Wallet')
        }
    }

    // helper function to get panda wallet object if it's ready and connected
    private async _getPanda(): Promise<any> {
        if ('panda' in window && window.panda?.isReady) {
            if (!(await window.panda.isConnected())) {
                await window.panda.connect()
            }
            return window.panda
        } else {
            console.error('Panda Wallet is not available or not ready')
            throw new Error('Panda Wallet is not available or not ready')
        }
    }

    async getBalance(): Promise<void> {
        if (!window.panda) return
        const { satoshis: currentUserBsvBalance } = await window.panda.getBalance()
        useStore.getState().set({
            currentUserBsvBalance,
        })
    }

    async getAddress(): Promise<void> {
        if (!window.panda) return
        const { identityAddress: currentUserBsvAddress } = await window.panda.getAddresses()
        useStore.getState().set({
            currentUserBsvAddress,
        })
    }

    // placeholders for the methods we're disregarding for now
    async getPaymail(): Promise<string> {
        // not implemented for panda wallet yet
        return Promise.reject('getPaymail not implemented for PandaWallet')
    }

    async sendBsv(address: string, amountSats: number): Promise<string> {
        // not implemented for panda wallet yet
        return Promise.reject('sendBsv not implemented for PandaWallet')
    }
}

/**
 * instantiates a wallet based on the provided wallet type.
 * @param walletType The type of the wallet to be instantiated.
 * @returns An instance of IWalletInterface.
 */
export async function instantiateWallet(walletType: BsvWalletType): Promise<IWalletInterface> {
    let wallet: IWalletInterface
    switch (walletType) {
        case BsvWalletType.SHUALLET:
            wallet = new SHUAllet()
            break
        case BsvWalletType.PANDA:
            // Placeholder for Panda wallet instantiation
            // wallet = new PandaWallet();
            break
        default:
            throw new Error('Invalid wallet type')
    }
    return wallet
}
