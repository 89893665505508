import React from 'react'
import cn from 'classnames'
import styles from 'components/shared/icons/icons.module.scss'

export default function TwetchIcon(props) {
    return (
        <div className={cn(styles.twetchIcon, props.contextStyles)}>
            <div className={props.initialStyles}>TW</div>
        </div>
    )
}
