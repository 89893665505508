import React from 'react'
import cn from 'classnames'
import NewAnswerDndWrapper from 'components/answers/NewAnswerDndWrapper'
import NewAnswer from 'components/answers/NewAnswer'
import QuestOptions from 'components/quests/QuestOptions'
import Connector from 'components/state/Connector'
import api from 'api/api'
import perms from 'lib/perms'
import { isMobile } from 'react-device-detect'
import AnswerDndContainer from 'components/answers/AnswerDndContainer'
import styles from './quest.module.scss'
import expandStyles from 'components/quests/Quest/expand-controls.module.scss'
import { scrollToSelectedAnswer } from '../../../state/imperativeApis/scrollApi'
import { isEqual, omitBy } from 'lodash'
import WithTooltip from 'components/shared/WithTooltip'
import domtoimage from 'dom-to-image'
import { Time } from 'components/users/UserName'
import { CryptoVoteTotal } from 'components/votes/Vote'
import * as cache from 'state/cache'
import { assignActiveNewAnswerRef, assignSideNewAnswerRef } from 'state/imperativeApis/editorApi'
import model from 'components/quests/questModel'
import answerModel from 'components/answers/answerModel'
import questModel from 'components/quests/questModel'
import useStore from 'state/knovStore'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import Perms from 'components/quests/Perms'
import ThreadControl from 'components/quests/Quest/ThreadControl'
import Bot from 'assets/knov-answer.svg'
import {
    slideTo,
    getCenterIndex,
    isPanelCenter,
    isPanelLeft,
    isPanelRight,
} from 'state/imperativeApis/swiperApi'
import useInsertLeft from 'refactor/hooks/useInsertLeft'
import { v4 as uuid } from 'uuid'
import useInsertRight from 'refactor/hooks/useInsertRight'
import useRemoveLeft from 'refactor/hooks/useRemoveLeft'
import useSlideToPanel from 'refactor/hooks/useSlideToPanel'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useRemoveRight from 'refactor/hooks/useRemoveRight'
import { defaultModel } from 'components/quests/KnovAgentButtonUIMain'

const stateSelector = (state, props) => {
    let quest = props.quest
    //quest = { ...quest, sorted_answers: quest.sorted_answers.map(a => state.answers[a.id]) }

    const serverMatches = props.quest.matching_answer_ids || []
    const clientMatches = quest.matching_answer_ids || []
    const hasClientMatches = clientMatches?.length > 0
    const hasServerMatches = serverMatches?.length > 0

    // The matching_answer_ids assignment will break shouldComponentUpdate's Object.is() check so only do it if there is a change to render.
    if (hasServerMatches && !isEqual(serverMatches, clientMatches)) {
        //console.log('NOT EQUAL', quest.matching_answer_ids, props.quest.matching_answer_ids)
        quest = { ...quest, matching_answer_ids: serverMatches }
    } else if (!hasServerMatches && hasClientMatches) {
        // Clear outdated view context attributes.
        quest = { ...quest, matching_answer_ids: [] }
    }

    return {
        quest,
        selectedAnswer: state.selectedAnswer,
        middleFilter: state.middleFilter,
        agentQuestIds: state.agentQuestIds,
        questAgentModels: state.questAgentModels,
        knovBotPendingStatus: state.knovBotPendingStatus || {},
        showAll:
            props.showAll ||
            (props.panel === 'middle'
                ? state.middlePanelExpandedQuests[props.quest.id]
                : state.rightPanelExpandedQuests[props.quest.id]),
    }
}

const CONTROL_BUTTONS = {
    perms: 'perms',
    filter: 'filter',
    sort: 'sort',
    edit: 'edit',
    search: 'search',
    tweet: 'tweet',
    blog: 'blog',
    export: 'export',
    delete: 'delete',
    crypto: 'crypto',
    play: 'play',
    copy: 'copy',
    collapse: 'collapse',
}

class Quest extends React.PureComponent {
    constructor(props) {
        super(props)

        this.SHOW_PREVIEW = 1
        this.SHOW_NEW = 3
        this.SHOW_MATCHING = 3

        this.state = {
            questId: this.props.quest.id,
            questPublic: this.props.quest.public,
            questTeam: this.props.quest.team,
            edit: false,
            parentAnswerEditMode: false,
            showAll: this.props.showAll,
            collapseActivated: false,
            answersShown: 0,
            newSessionAnswers: [],
        }

        this.ensureNewAnswer()
    }

    containerRef = React.createRef()
    parentAnswerRef = React.createRef()

    componentWillReceiveProps = nextProps => {
        if (this.props.showAll != nextProps.showAll && nextProps.showAll != this.state.showAll) {
            this.setState({ showAll: nextProps.showAll })
        }
    }

    collapseHandler = () => {
        const collapsePosition = this.props.quest.sorted_answers.reduce((a, b) =>
            a.position > b.position ? a : b,
        ).position

        this.setState({
            showAll: false,
            collapseActivated: true,
            answersShown: 0,
            collapsePosition: collapsePosition + 1,
        })
    }

    focusNewAnswer = timeout => {
        setTimeout(() => {
            this.newAnswer?.current?.richEditor?.current?.focus()
        }, timeout || 100)
    }

    componentDidMount = async () => {
        if (this.props.activeMiddle && location.href.match(/\/new$/)) {
            // TODO HACK that we have to set a delay, but something is clobbering the focus before that...
            this.focusNewAnswer(2000)
        }

        if (this.props.shouldPlay) {
            useStore.getState().actions.modalActions.openStoryModal(this.props.quest)
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.ensureNewAnswer()
        function getDiff(oldProps, oldState, newProps, newState) {
            const propDiff = omitBy(newProps, (value, key) => Object.is(value, oldProps[key]))
            const stateDiff = omitBy(newState, (value, key) => Object.is(value, oldState[key]))
            return { props: propDiff, state: stateDiff }
        }
        const diff = getDiff(prevProps, prevState, this.props, this.state)
        //console.log('QUEST DIFF', diff)
    }

    matchingAnswers = () => {
        const quest = this.props.quest
        const filtered = quest.sorted_answers.filter(ans =>
            quest.matching_answer_ids.includes(ans.id),
        )
        return filtered.sort(
            (a, b) =>
                quest.matching_answer_ids.indexOf(a.id) - quest.matching_answer_ids.indexOf(b.id),
        )
    }

    votedAnswers = () => model.votedAnswers(this.props.quest.sorted_answers)

    positionAnswers = () => model.positionAnswers(this.props.quest.sorted_answers)

    sortedAnswers = () => model.sortedQuestAnswers(this.props.quest)

    filterAnswers = answers => {
        if (!answers) return null

        let filteredAnswers
        if (this.state.filter === 'archive') {
            filteredAnswers = answers.filter(ans => ans?.archive)
        } else if (this.state.filter === 'all') {
            filteredAnswers = answers
        } else if (!this.state.filter || this.state.filter === 'none') {
            filteredAnswers = answers.filter(ans => !ans?.archive)
        } else {
            filteredAnswers = answers.filter(
                ans => ans.action_tags && ans.action_tags[this.state.filter],
            )
        }

        return filteredAnswers
    }

    displayAnswers = () => {
        //console.log('display answers', this.state.answersShown)
        let sorted_answers = this.props.quest.sorted_answers || []

        const quest = this.props.quest
        //console.log('DISP ANSWERS', quest.sorted_answers.map(a => [a.id, a.content, a.position]))

        let answers

        if (!this.state.showAll && this.state.collapseActivated) {
            //console.log('collapse vars', this.state.collapsePosition, this.state.answersShown)
            if (this.state.answersShown == 0) answers = []
            else if (!quest.default_sort_col || quest.default_sort_col == 'position') {
                let dispAns = this.filterAnswers(this.sortedAnswers())
                    .slice(-this.state.answersShown)
                    .filter(a => a.position > this.state.collapsePosition)
                // console.log('display by position', dispAns)
                answers = dispAns
            } else {
                let dispAns = this.filterAnswers(this.sortedAnswers())
                    .slice(0, this.state.answersShown)
                    .filter(a => a.position <= this.state.collapsePosition)
                //console.log('display by votes', dispAns)
                answers = dispAns
            }
        } else if (this.state.showAll) {
            answers = this.filterAnswers(this.sortedAnswers())
        } else if (quest.matching_answer_ids && quest.matching_answer_ids.length > 0) {
            answers = this.filterAnswers(this.matchingAnswers()?.concat(this.newSessionAnswers()))
        } else if (quest.defaut_sort_col === 'vote') {
            answers = this.filterAnswers(quest.sorted_answers).slice(
                0,
                this.SHOW_PREVIEW + this.state.answersShown,
            )
        } else {
            const filtered = this.filterAnswers(quest.sorted_answers)
            const len = filtered.length
            const lastCreated = filtered
                .sort((a, b) => a.created_at - b.created_at)
                .slice(len - this.SHOW_NEW - this.state.answersShown, len)
            answers = lastCreated.sort((a, b) => a.position - b.position)
        }

        // prepend title answer (quest.parent) to the list of answers
        let sortedAnswers = [quest.parent, ...answers]
        return sortedAnswers
    }

    newSessionAnswers = () => this.state.newSessionAnswers || []

    moreAnswers = () => {
        const sorted_answers = this.props.quest?.sorted_answers || []
        const filteredAnswers = this.filterAnswers(sorted_answers)
        //console.log('more answers', filteredAnswers, this.state.collapsePosition, this.props.quest.sorted_answers, this.displayAnswers())
        if (filteredAnswers) return filteredAnswers.length - (this.displayAnswers().length - 1)
        else return 0
    }

    isDraft = () => this.props.quest.is_draft
    showNewAnswer = () =>
        !this.isDraft() && !(this.props.quest.user?.system || this.props.quest.is_system)

    deleteQuest = async ev => {
        this.closeQuestOptions()
        const { panel } = this.props.panelContext
        useStore.getState().panels.editPanel(panel.panelId, { empty: true, filter: {} })
        api.deleteQuest(this.props.quest.id)
        history.replaceState({}, '', '/')
    }

    tweet = providerId => {
        const answers = document.querySelectorAll(`#quest-${this.props.quest.id} .answer.comp`)
        let imageProms = []
        let imageData = {}

        for (let i = 0; i < answers.length; i++) {
            let answer = answers[i]
            let df = new Promise((resolve, reject) => {
                return { resolve, reject }
            })
            imageProms.push(df)

            let ansSel = answer.querySelector('.web-quote-inner')

            ansSel.style.marginLeft = 5
            let w = ansSel.offsetWidth
            let h = ansSel.offsetHeight
            let fs = window.getComputedStyle(ansSel).getPropertyValue('font-size')
            ansSel.style.width = 2 * w
            ansSel.style.height = 2 * h
            ansSel.style.fontSize = parseFloat(fs, 10) * 2

            if (ansSel) {
                domtoimage
                    .toPng(ansSel, {
                        bgcolor: 'white',
                        quality: 0.99,
                    })
                    .then(function (data) {
                        ansSel.style.width = w + 'px'
                        ansSel.style.height = h + 'px'
                        ansSel.style.fontSize = fs
                        ansSel.style.marginLeft = 0
                        imageData[answer.dataset.answerId] = data
                        df.resolve()
                        return this
                    })
                    .catch(function (error) {
                        ansSel.style.width = w + 'px'
                        ansSel.style.height = h + 'px'
                        ansSel.style.fontSize = fs
                        ansSel.style.marginLeft = 0
                        console.error('oops, something went wrong!', error)
                    })
            } else {
                imageData[answer.dataset.answerId] = '_no_selection_'
                df.resolve()
            }
        }

        return Promise.all(imageProms).then(() => {
            return fetch('/quests/create_tweet', {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': gon.formToken,
                },
                body: JSON.stringify({
                    provider_id: providerId,
                    quest_id: this.props.quest.id,
                    image_data: imageData,
                }),
            })
        })
    }

    addAnswer = answer => {
        // This updates collapsed state.
        !this.isRankedQuest() &&
            this.setState(state => {
                return {
                    answersShown: (state.answersShown || 0) + 1,
                    newSessionAnswers: [...state.newSessionAnswers, answer],
                }
            })
    }

    insertAnswer = async answerId => {
        const quest = this.props.quest
        const answers = quest.sorted_answers
        const ix = answers.findIndex(a => a.id === answerId)
        const answer = answers[ix]

        let newPos
        if (ix === 0) {
            newPos = answer.position / 2
        } else if (ix > 0) {
            newPos = (answers[ix - 1].position + answer.position) / 2
        }

        const newAnswer = answerModel.newAnswer({
            quest_id: quest.id,
            position: newPos,
            is_draft: false,
        })
        // Setup side quest.
        const perms = questModel.getPerms(quest)
        const sideQuest = questModel.newQuest({ ...perms, parent: newAnswer, is_draft: false })

        cache.cacheQuest(sideQuest)
        cache.cacheAnswer({ ...newAnswer, child_quests: [{ id: sideQuest.id }] })

        api.createAnswer(newAnswer)
    }

    updateAnswer = answer => {
        //console.log('UPDATE ANSWER', answer)
        if (answer.id === this.props.quest?.parent?.id) {
            // Update parent answer.
            const newQuest = {
                ...this.props.quest,
                parent: answer,
            }
            useStore.getState().updateQuest(newQuest)
            // Update parent quest if it exists.
            if (answer.quest_id) {
                const newParentQuest = {
                    ...cache.getCachedQuest(answer.quest_id),
                    updated_at: new Date(),
                }
                useStore.getState().updateQuest(newParentQuest)
            }
        } else {
            // Update sorted answers.
            const ix = this.props.quest.sorted_answers.findIndex(ans => ans.id === answer.id)
            if (ix !== null) {
                const newAnswers = [...this.props.quest.sorted_answers]
                newAnswers[ix] = answer
                const newQuest = Object.assign({}, this.props.quest, { sorted_answers: newAnswers })
                useStore.getState().updateQuest(newQuest)
                // Update child quest if it exists.
                if (answer?.child_quests?.[0]) {
                    const newChildQuest = {
                        ...answer.child_quests[0],
                        updated_at: new Date(),
                        parent: answer,
                    }
                    useStore.getState().updateQuest(newChildQuest)
                }
            }
        }
    }

    removeAnswer = answerId => {
        const newAnswers = this.props.quest.sorted_answers.filter(ans => ans.id !== answerId)
        const newQuest = {
            ...this.props.quest,
            updated_at: Date.now(),
            sorted_answers: newAnswers,
        }
        useStore.getState().updateQuest(newQuest)
    }

    showQuest = ev => {
        let questPath = this.props.quest.path
        if (!$(ev.target).hasClass('delete-quest') && !this.state.edit) {
            if (ev.metaKey) window.open(questPath, '_blank')
            else window.location.pathname = questPath
        }
    }

    setEdit = edit => this.setState({ parentAnswerEditMode: edit })

    clearSelection = () => {
        // Handle click if selection.
        const selection = window.getSelection()
        const hasTextSelection = selection.toString().length > 0
        let anchor = selection?.anchorNode
        let anchorInSelection
        if (anchor) {
            if (anchor.nodeType !== Node.ELEMENT_NODE) anchor = anchor.parentElement
            anchorInSelection = !!anchor.closest('.with-selection-comp')
        }

        // If there is a text selection we want to clear it instead of toggling selected answer.
        //console.log('hasSelection', selection, hasTextSelection, this.hasSelection)
        if (hasTextSelection || this.hasSelection) {
            if (!anchorInSelection || this.clickOutsideSelection) {
                selection.removeAllRanges()
            }
            this.clickOutsideSelection = null
            this.hasSelection = null
            return true
        } else if (isMobile && anchorInSelection && !selection.isCollapsed) {
            // Mobile doesn't reliably return toSting() or clears the selection sometime earlier? so just use anchor to check.
            // Cant use anchor for desktop bc it might be set even if no selection and we want to be able to click on text to navigate.
            this.clickOutsideSelection = null
            this.hasSelection = null
            return true
        } else return false
    }

    onClickTitle = ev => {
        ev.preventDefault()
        ev.stopPropagation()
        const { panelId, filter, isActive, slideToPanel, insertRight, removeRight } = this.props

        // Can't usePanelContext but we can use the imperative api.
        const activeLeft = isActive && isPanelLeft(panelId)
        const activeCenter = isActive && isPanelCenter(panelId)
        const activeRight = isActive && isPanelRight(panelId)

        const quest = this.props.quest
        if (quest.is_draft || quest.user?.system || this.state.parentAnswerEditMode) {
            return
        }
        if (this.clearSelection()) return

        if (activeCenter) {
            // TODO focus or new seesion ?
            return
        } else if (activeLeft || activeRight) slideToPanel()
        else {
            const rightPanel = useStore.getState().panels.getPanelToRight(panelId)
            const rightQuestId = rightPanel?.filter?.questId
            const rightQuest = cache.getCachedQuest(rightQuestId)
            const rightParentId = rightQuest?.parent?.id
            if (rightParentId && rightParentId === quest.parent?.id) {
                useStore.getState().set(draft => {
                    draft.selectedAnswers[panelId] = null
                })
                removeRight()
                // quest is not active and is part of a stream, insert right panel.
            } else {
                useStore.getState().set(draft => {
                    draft.selectedAnswers[panelId] = {
                        answerId: quest.parent?.id,
                    }
                })
                insertRight({ filter: { questId: quest.id } })
            }
        }
    }

    onLayout = ev => {
        this.height = ev.nativeEvent.layout.height
    }

    expandAnswers = ev => {
        ev?.preventDefault()
        ev?.stopPropagation()
        this.setExpandedQuest()
        this.setState({ showAll: true, collapsePosition: null, answersShown: 0 })
    }

    expandAnswersPartial = ev => {
        ev?.preventDefault()
        ev?.stopPropagation()
        this.setState(state => ({ answersShown: state.answersShown + 3 }))
    }

    showParentAnswer = () => {
        return !!this.props.quest.parent && this.props.quest.side_quest
    }

    showPostInspiration = () => {
        const answer = this.props.quest.parent
        return answer
    }

    showOriginInspiration = () => {
        const quest = this.props.quest
        return !!quest.url && quest.inspiration
    }

    showInspiration = () => {
        return this.showPostInspiration() || this.showOriginInspiration()
    }

    inspirationType = () => {
        if (this.showPostInspiration()) return 'post'
        if (this.showOriginInspiration()) return 'origin'
        return null
    }

    isUnacted = () => {
        const quest = this.props.quest
        return (
            quest.has_acted === false &&
            quest.not_acted_answer_ids &&
            quest.not_acted_answer_ids.length === 0
        )
    }

    isSideQuest = () => this.props.quest.side_quest

    setAnswerFilter = filter => {
        this.setState({ filter })
    }

    showAnswerFilter = showAnswerFilter => {
        this.setState({ showAnswerFilter })
    }

    newAnswer = React.createRef()

    closeOptionsState = {
        showQuestOptions: false,
        showTypeOptions: false,
        showPermsOptions: false,
        showFilterDropdown: false,
        showSortColDropdown: false,
        showExtendedOptions: false,
    }

    closeQuestOptions = () => {
        this.setState(this.closeOptionsState)
    }

    setShowExtendedOptions = show => {
        this.setState({
            ...this.closeOptionsState,
            showQuestOptions: show,
            showExtendedOptions: show,
        })
    }

    setShowTypeOptions = show => {
        if (perms.amQuestOwner(this.props.quest))
            this.setState({
                ...this.closeOptionsState,
                showQuestOptions: show,
                showTypeOptions: show,
            })
        else return
        //alert('You do not have permission to change this quest type.')
    }

    setShowPermsOptions = show => {
        if (perms.amQuestOwner(this.props.quest))
            this.setState({
                ...this.closeOptionsState,
                showQuestOptions: show,
                showPermsOptions: show,
            })
        else alert('You do not have permission to edit this thread.')
    }

    setShowFilterDropdown = show => {
        this.setState({
            ...this.closeOptionsState,
            showQuestOptions: show,
            showFilterDropdown: show,
        })
    }

    setShowSortColDropdown = show => {
        this.setState({
            ...this.closeOptionsState,
            showQuestOptions: show,
            showSortColDropdown: show,
        })
    }

    setShowExtendedOptions = show => {
        this.setState({
            ...this.closeOptionsState,
            showQuestOptions: show,
            showExtendedOptions: show,
        })
    }

    isPinned = () => {
        const quest = this.props.quest
        const team = quest.team
        return team && quest.id === team.quest_id
    }

    showPin = () => {
        const team = this.props.quest.team
        const isTeamAdmin = team && gon.currentUser && gon.currentUser.id === team.admin_id
        const isActive = this.props.activeMiddle
        const isHover = this.state.questionHover || this.state.pinHover
        const isPinned = this.isPinned()
        if (isActive && isTeamAdmin) {
            return isHover || isPinned
        } else {
            // hmm... why again?
            return this.props.pinned
        }
    }

    pinToTeam = () => {
        const quest = this.props.quest
        const team = quest.team
        if (team) {
            useStore.getState().pinQuestToTeam(team.id, quest)
        }
    }

    unpinFromTeam = () => {
        const quest = this.props.quest
        const team = quest.team
        if (team) {
            useStore.getState().unpinQuestFromTeam(team.id, quest)
        }
    }

    getPosition = (sortedArr, dragStartIdx, dragEndIdx) => {
        const dest = sortedArr[dragEndIdx]
        let out
        if (dragEndIdx == 0) {
            out = sortedArr[0].position - 1
        } else if (dragEndIdx == sortedArr.length - 1) {
            out = sortedArr[sortedArr.length - 1].position + 1
        } else {
            const cur = sortedArr[dragEndIdx]
            const prev = sortedArr[dragEndIdx - 1]
            const next = sortedArr[dragEndIdx + 1]
            if (dragEndIdx > dragStartIdx) {
                out = cur?.position + ((next?.position || 0) - cur?.position) / 2
            } else {
                out = (prev?.position || 0) + (cur?.position - (prev?.position || 0)) / 2
            }
        }
        return out
    }

    moveAnswer = async (dragStartIdx, dragEndIdx) => {
        let sortedAnswers = [...this.displayAnswers()]
        const currentQuestId = this.props?.quest?.id

        if (dragStartIdx == 0 || dragEndIdx == 0) {
            // doing a title swap
            let origTitleAnswer = sortedAnswers[0]
            let firstNonTitleAnswer = sortedAnswers?.[1]
            const draggedAnswer = sortedAnswers[dragStartIdx]

            // api.swapAnswer(origTitleAnswer?.id, firstNonTitleAnswer?.id, { optimistic: true })

            if (dragEndIdx == 0) {
                // dragging answer into title slot

                // lock quest in cache to prevent race condition re-renders
                useStore.getState().lockQuest(draggedAnswer?.quest_id)

                // then swap it into the title slot
                await api.swapAnswer(draggedAnswer?.id, origTitleAnswer?.id)

                useStore.getState().unlockQuest(draggedAnswer?.quest_id)

                if (this.props?.selectedAnswer?.id == origTitleAnswer?.id) {
                    // TODO PANEL REFACTOR
                    // Highlight Selected Answer
                }
            } else if (dragStartIdx == 0) {
                // dragging answer out of title slot
                useStore.getState().lockQuest(firstNonTitleAnswer?.quest_id)

                const swapResult = await api.swapAnswer(
                    origTitleAnswer?.id,
                    firstNonTitleAnswer?.id,
                    {
                        // optimistic: true, // something's up with optimistic logic here
                    },
                )

                const newPosition = this.getPosition(
                    sortedAnswers.slice(1),
                    0,
                    dragEndIdx - 1 < 0 ? 0 : dragEndIdx - 1,
                )

                const posUpdateResult = await api.updateAnswer(draggedAnswer?.id, {
                    position: newPosition,
                })

                useStore.getState().unlockQuest(firstNonTitleAnswer?.quest_id)

                cache.cacheQuests(swapResult?.updated_quests)
                cache.cacheAnswer(posUpdateResult)

                if (this.props?.selectedAnswer?.id == draggedAnswer?.id) {
                    // TODO PANEL REFACTOR
                    // Highlight Selected Answer
                }
            }
        } else {
            // just a normal answer move
            const srcAnswer = sortedAnswers?.[dragStartIdx]

            // slice answers array and update indexes to match to account for
            // title answer
            const newPosition = this.getPosition(
                sortedAnswers.slice(1),
                dragStartIdx - 1,
                dragEndIdx - 1,
            )
            api.updateAnswer(srcAnswer?.id, { position: newPosition }, { optimistic: true })
        }
    }

    moveAnswerBetweenQuests = async (hoverIndex, answerId) => {
        // account for title answer
        let [title, ...sortedAnswers] = [...this.displayAnswers()]
        let dragEndIdx = hoverIndex - 1
        const numOfSlots = sortedAnswers.length

        let dragStartIdx = numOfSlots
        if (dragEndIdx > numOfSlots && numOfSlots > 0) {
            dragStartIdx = 0
            dragEndIdx = numOfSlots
        } else if (dragEndIdx == numOfSlots) {
            dragStartIdx = 0
        }
        if (dragEndIdx < 0) dragEndIdx = 0
        if (dragStartIdx < 0) dragStartIdx = 0

        let newPos
        // just set position to 1 if the sidequest only has a title answer
        if (sortedAnswers.length < 1) {
            newPos = title?.position + 1
        } else if (sortedAnswers.length == 1) {
            if (dragEndIdx >= dragStartIdx) {
                newPos = sortedAnswers?.[0]?.position + 1
            } else {
                newPos = sortedAnswers?.[0]?.position - 1
            }
        } else {
            newPos = this.getPosition(sortedAnswers, dragStartIdx, dragEndIdx)
        }

        const srcAnswer = await api.updateAnswer(
            answerId,
            {
                position: newPos,
                quest_id: this.props.quest.id,
            },
            { optimistic: true },
        )

        if (hoverIndex == 0) {
            // user is dragging an answer into quest's title slot, so swap the
            // tgt quest's first non-title answer with with the answer being
            // dragged
            const tgtAnswer = cache.getCachedQuest(this.props?.quest?.id)?.sorted_answers?.[1]

            if (srcAnswer?.id && tgtAnswer?.id) {
                await api.swapAnswer(srcAnswer.id, tgtAnswer.id, { optimistic: true })
            }
        }
        // NOTE: sadly without this setTimeout() zustand does not re-render
        // after a move between quests, I am not yet sure why so to workaround
        // issue an  empty quest update on the next tick
        setTimeout(() => {
            cache.updateCachedQuest(this.props.quest.id, {})
        }, 0)
    }

    resetOtherQuest = async id => {
        const dragQuest = await api.getQuest(id)
        useStore.getState().updateQuest(dragQuest)
    }

    appendAnswerToQuest = answerId => {
        let newSortedAnswers = [...this.displayAnswers()]
        let newPosition
        if (newSortedAnswers.length > 0) {
            newPosition = newSortedAnswers[newSortedAnswers.length - 1].position + 1
        } else {
            newPosition = Date.now() / 1000
        }
        api.updateAnswer(answerId, { position: newPosition, quest_id: this.props.quest.id })

        const quest = this.props.quest
        const answer = cache.getCachedAnswer(answerId)
        if (answer)
            cache.cacheQuest({ ...quest, sorted_answers: [...quest.sorted_answers, answer] })
    }

    showParentButton = () => !!this.props.quest.parent?.quest_id

    onClickParentButton = async ev => {
        ev.preventDefault()
        ev.stopPropagation()

        const { quest, removeLeft, insertLeft } = this.props
        const { panel } = this.props.panelContext
        const leftPanel = useStore.getState().panels.getPanelToLeft(panel.panelId)
        const leftQuestId = leftPanel?.filter?.questId
        if (leftQuestId && leftQuestId === quest.parent.quest_id) {
            if (isMobile) slideTo(getCenterIndex() - 1)
            else {
                removeLeft()
                useStore.getState().set(draft => {
                    draft.selectedAnswers[leftPanel.panelId] = null
                })
            }
        } else {
            // We generate an id manually so we know what to set as selected.
            const newLeftPanelId = uuid()
            insertLeft({ panelId: newLeftPanelId, filter: { questId: quest.parent.quest_id } })
            useStore.getState().set(draft => {
                draft.selectedAnswers[newLeftPanelId] = { answerId: quest.parent?.id }
            })
        }
    }

    onHeadEnter = () => {
        if (this.isSystemUser()) return
        this.setState({ questionHover: true, pinHover: true })
    }

    onHeadLeave = () => {
        if (this.isSystemUser()) return
        this.setState({ questionHover: false, pinHover: false })
    }

    isRankedQuest = () => this.props.quest.default_sort_col === 'vote'

    // Generate and cache a "new answer (draft)", to write in. This is diffirent than a draft answer for existing published answers.
    ensureNewAnswer = () => {
        const quest = this.props.quest
        let newAnswer = quest.new_answer
        if (!newAnswer) {
            newAnswer = answerModel.newAnswer({
                quest_id: quest.id,
                space_id: quest.space_id,
                isDraft: true,
            })
            // Setup side quest.
            const perms = questModel.getPerms(quest)
            const sideQuest = questModel.newQuest({ ...perms, parent: newAnswer, is_draft: false })
            newAnswer.child_quests = [{ id: sideQuest.id }]
            quest.new_answer = newAnswer
            cache.cacheQuests([quest, sideQuest])
        }
        return newAnswer
    }

    getNewAnswer = () => {
        const quest = this.props.quest
        let newAnswer
        if (quest.is_draft) newAnswer = quest.parent
        else {
            newAnswer = quest.new_answer
        }
        return newAnswer
    }

    getPanel = () => useStore.getState().panels.getPanel(this.props.panelId)
    isActive = () => this.getPanel().filter?.questId

    isSystemUser = () =>
        this.props.quest.is_system ||
        this.props.quest?.user?.system ||
        this.props.quest.user_id === gon.SYSTEM_USER

    render() {
        const { isActive, activeMiddle } = this.props
        const quest = this.props.quest
        // [[ Learn Knov Data Model ]].
        // quest.parent is the title answer ie. head of the quest, w/ quest.sorted_answers being the tail.
        // When we branch from an answer, the answer becomes the parent or title or head of the branched quest (side quest).
        const parent = quest.parent

        //console.log('QUEST RENDERING', quest.id, quest)

        const empty =
            this.props.quest.sorted_answers && this.props.quest.sorted_answers.length == 0
                ? 'empty'
                : ''
        const actedClass = this.isUnacted() ? 'unacted' : ''
        const mobileClass = isMobile ? 'is-mobile' : ''

        const hoverStyles = !quest.is_draft && this.state.questionHover ? styles.hover : null

        const systemUser = this.isSystemUser()
        const systemUserStyles = systemUser ? styles.systemUser : null

        const moreAnswers = this.moreAnswers()
        //console.log('moreAnswers', moreAnswers)
        const showStats = moreAnswers > 0
        const showStatsBottom = showStats && this.isRankedQuest()
        const showStatsTop = showStats && !showStatsBottom
        let statsBorderStyle
        //if (showStatsBottom || this.displayAnswers()?.length === 1) statsBorderStyle = expandStyles.statsBottomBorder
        if (showStatsBottom && this.displayAnswers()?.length > 1)
            statsBorderStyle = expandStyles.statsTopBorder
        //const newAnswerBorderStyle = showStatsBottom
        //console.log('bottom stats style', statsBorderStyle, showStatsBottom, this.displayAnswers()?.length)
        const isActiveRight = isActive && isPanelRight(this.props.panelContext?.panelId)
        const sideQuestParentButtonStyle = isActiveRight ? styles.sideQuestParentButton : null
        let pinClass, pinCallback, pinTip
        const team = quest.team
        if (team && this.isPinned()) {
            pinClass = 'pin-noti'
            pinCallback = this.unpinFromTeam
            pinTip = `Pinned to ${team.name}`
        } else if (team) {
            pinClass = 'pin-btn'
            pinCallback = this.pinToTeam
            pinTip = `Pin quest to ${team.name}`
        }

        const answerFilterCallback = val => this.setAnswerFilter(val)

        let answerFilterOptions = [
            {
                type: 'icon',
                name: 'DEFAULT',
                value: 'none',
                desc: 'No filter.',
                logo: 'fa fa-filter',
                closeOnClick: true,
                callback: answerFilterCallback,
            },
            {
                type: 'icon',
                name: 'ARCHIVED',
                value: 'archive',
                desc: 'Show archived posts.',
                logo: 'fa fa-folder',
                closeOnClick: true,
                callback: answerFilterCallback,
            },
            {
                type: 'icon',
                name: 'ALL',
                value: 'all',
                desc: 'Show all posts.',
                logo: 'fa fa-cube',
                closeOnClick: true,
                callback: answerFilterCallback,
            },
        ]

        let customAnswerFilterOptions =
            quest.sorted_answers &&
            [
                ...new Set(
                    quest.sorted_answers
                        ?.map(el => el?.action_tags && Object.keys(el.action_tags))
                        ?.flat(),
                ),
            ]
                ?.filter(Boolean) // Convert to array and remove empty strings.
                ?.map(tag => {
                    return {
                        type: 'icon',
                        name: tag.toUpperCase(),
                        value: tag,
                        desc: `Show posts with !${tag}`,
                        logo: 'fa fa-exclamation',
                        closeOnClick: true,
                        callback: answerFilterCallback,
                    }
                })

        if (customAnswerFilterOptions) {
            answerFilterOptions = answerFilterOptions.concat(customAnswerFilterOptions)
        }

        const isBlog = this.props.quest.blog
        const showStatus = parent?.archive

        const titleAnswerProps = {
            isTitle: true,
            isActive: this.props.isActive,
            expandAnswers:
                isActive && showStatsTop ? (
                    <div className={cn('quest-stats-container')}>
                        <ExpandAnswers
                            moreAnswers={this.moreAnswers()}
                            expandAnswers={this.expandAnswers}
                            expandAnswersPartial={this.expandAnswersPartial}
                            filter={this.props.filter}
                            numMatchingAnswers={this.matchingAnswers()?.length}
                            direction="down"
                            contextStyles={statsBorderStyle}
                            query={this.props.query}
                        />
                    </div>
                ) : (
                    <></>
                ),
            panel: this.props.panel,
            showInsert: false,
            showControls: true,
            showTopControls: false,
            showVotes: true,
            selectable: true,
            blog: this.props.quest.blog,
            CONTROL_BUTTONS: CONTROL_BUTTONS,
            outsideEdit: true,
            editMode: this.state.parentAnswerEditMode,
            setEdit: this.setEdit,
            parentEditor: this.parentAnswerRef,
            filter: this.props.filter,
            answerFilter: this.state.filter || 'none',
            updateAnswer: this.updateAnswer,
            onHeadEnter: this.onHeadEnter,
            onHeadLeave: this.onHeadLeave,
            questionHover: this.state.questionHover,

            onClickTitle: this.onClickTitle,

            setShowPermsOptions: this.setShowPermsOptions,
            showPermsOptions: this.state.showPermsOptions,

            setShowFilterDropdown: this.setShowFilterDropdown,
            showFilterDropdown: this.state.showFilterDropdown,

            setShowSortColDropdown: this.setShowSortColDropdown,
            showSortColDropdown: this.state.showSortColDropdown,

            setShowExtendedOptions: this.setShowExtendedOptions,
            showExtendedOptions: this.state.showExtendedOptions,

            collapseHandler: this.collapseHandler,
            showEmbedButton: this.props.showEmbedButton,
        }

        const newAnswerContainerDesktopStyles =
            isMobile || this.displayAnswers()?.length === 1 || showStatsBottom
                ? null
                : styles.newAnswerContainerDesktop

        const lastAnswerCreatedAt =
            quest.parent && quest.sorted_answers
                ? Math.max(
                      ...[quest.parent, ...quest.sorted_answers].map(a => new Date(a?.created_at)),
                  )
                : null

        const aggCryptoVoteTotal =
            quest.parent && quest.sorted_answers
                ? [quest.parent, ...quest?.sorted_answers].reduce(
                      (acc, ans) => acc + ans?.crypto_vote_total || 0,
                      0,
                  )
                : null

        //console.log('quest render', this.props.quest, this.props.quest?.parent, this.props.quest?.sorted_answers)
        //console.log('quest', quest.updated_at, 'parent', quest.parent.updated_at)
        //if (this.props.quest?.sorted_answers?.length === 0) console.trace()

        const newAnswer = this.getNewAnswer()

        // TODO .... are we going to run into rendering problems here since we're monitoring the whole object?
        const agentAwaiting = this.props.agentQuestIds.some(id => id === quest?.id)
            ? 'awaiting'
            : null
        const agentModel = this.props.questAgentModels[quest?.id] || defaultModel
        const agentStatus = this.props.knovBotPendingStatus[quest?.id] || agentAwaiting
        const isBsv = quest.public || this.props.quest?.team?.public

        const questFilter = this.props.filter?.questId
            ? questModel.getFilter(quest)
            : this.props.filter
        const showStreamRoot =
            questFilter?.public ||
            questFilter?.treechat ||
            questFilter?.hodlocker ||
            questFilter?.twetch

        return (
            <div
                ref={this.containerRef}
                id={`quest-${this.props.quest.id}`}
                className={cn('quest comp', mobileClass, styles.questComp, systemUserStyles)}
                data-quest={this.props.quest.id}
            >
                {!systemUser && (
                    <div>
                        {this.showParentButton() && (
                            <button
                                type={'button'}
                                className={cn(styles.parentButton, sideQuestParentButtonStyle)}
                                onClick={this.onClickParentButton}
                            >
                                <div className={styles.parentNode}></div>
                                <div className={styles.nodeLine}></div>
                                <div className={styles.childNode}></div>
                            </button>
                        )}

                        <div className={'quest-nav-container'}>
                            {this.showPin() && (
                                <div
                                    className={`pin ${pinClass}`}
                                    data-tip={pinTip}
                                    onClick={pinCallback}
                                    onMouseEnter={() => this.setState({ pinHover: true })}
                                    onMouseLeave={() => this.setState({ pinHover: false })}
                                >
                                    <i className="fa fa-thumb-tack" />
                                </div>
                            )}
                        </div>

                        <div
                            className={cn(
                                styles.questionContainer,
                                hoverStyles,
                                systemUserStyles,
                                `question-container ${empty} ${mobileClass}`,
                            )}
                            onMouseDown={ev => {
                                const hasSelection = window.getSelection().toString().length > 0
                                //console.log('mousedown', hasSelection)
                                // <WithSelection> prevents the event so this wont fire and wont be set.
                                // We set this so we know to clear the selection in this.toggleSelectedAnswer()
                                if (hasSelection) {
                                    // The selection exists on mousedown but gets blurred before the onClick in some scenarios, so we track it with a flag.
                                    this.hasSelection = hasSelection
                                    this.clickOutsideSelection = true
                                }
                            }}
                            onTouchStart={ev => {
                                const hasSelection = window.getSelection().toString().length > 0
                                //console.log('touchstart', hasSelection)
                                // <WithSelection> prevents the event so this wont fire and wont be set.
                                // We set this so we know to clear the selection in this.toggleSelectedAnswer()
                                if (hasSelection) {
                                    // The selection exists on mousedown but gets blurred before the onClick in some scenarios, so we track it with a flag.
                                    this.hasSelection = hasSelection
                                    this.clickOutsideSelection = true
                                }
                            }}
                            onMouseEnter={this.onHeadEnter}
                            onMouseLeave={this.onHeadLeave}
                        >
                            <div className={`acted-noti ${actedClass}`} />

                            <div
                                className={cn(styles.questHeaderContainer, hoverStyles)}
                                onClick={this.onClickTitle}
                            >
                                <div className={styles.threadControlContainer}>
                                    <div className={styles.leftControlContainer}>
                                        {showStreamRoot && (
                                            <div className={styles.streamRootPath}>
                                                <Perms
                                                    quest={quest}
                                                    filter={this.props.filter}
                                                    panel={this.props.panel}
                                                    showPermsOptions={this.state.showPermsOptions}
                                                    setShowPermsOptions={this.setShowPermsOptions}
                                                    isBsv={isBsv}
                                                    isRoot={true}
                                                />

                                                <div className={styles.breadSlash}>/</div>
                                            </div>
                                        )}
                                        <Perms
                                            quest={quest}
                                            filter={this.props.filter}
                                            panel={this.props.panel}
                                            showPermsOptions={this.state.showPermsOptions}
                                            setShowPermsOptions={this.setShowPermsOptions}
                                            isBsv={isBsv}
                                        />
                                    </div>

                                    {true && (
                                        <div className={styles.rightControlContainer}>
                                            <div className={styles.cornerControlsContainer}>
                                                {!this.isDraft() && (
                                                    <>
                                                        {aggCryptoVoteTotal > 0 && (
                                                            <>
                                                                <div
                                                                    className={
                                                                        styles.cryptoVoteContainer
                                                                    }
                                                                >
                                                                    <WithTooltip tip="Aggregate thread upvalue.">
                                                                        <CryptoVoteTotal
                                                                            voteTotal={
                                                                                aggCryptoVoteTotal
                                                                            }
                                                                            votableId={quest.id}
                                                                            votableType={'Quest'}
                                                                            visible={
                                                                                systemUser
                                                                                    ? 'visible'
                                                                                    : ''
                                                                            }
                                                                            color={null}
                                                                            //toggleCrypto={this.toggleCrypto}
                                                                            //openCryptoModal={this.openCryptoModal}
                                                                        />
                                                                    </WithTooltip>
                                                                </div>
                                                                {aggCryptoVoteTotal > 0 && '|'}
                                                            </>
                                                        )}

                                                        <div className={styles.timeContainer}>
                                                            <Time
                                                                time={lastAnswerCreatedAt}
                                                                tip={'Latest thread message.'}
                                                            />
                                                        </div>

                                                        <div
                                                            className={styles.typeControlContainer}
                                                        >
                                                            <ThreadControl
                                                                quest={quest}
                                                                showTypeOptions={
                                                                    this.state.showExtendedOptions
                                                                }
                                                                setShowTypeOptions={
                                                                    this.setShowExtendedOptions
                                                                }
                                                            />

                                                            {showStatus && (
                                                                <div
                                                                    className={styles.status}
                                                                ></div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {this.isDraft() && (
                                <>
                                    {!!agentStatus && (
                                        <NewAnswerStatus
                                            showBorderBottom
                                            status={agentStatus}
                                            agentModel={agentModel}
                                        />
                                    )}

                                    <div className={styles.newTitleContainer}>
                                        <NewAnswer
                                            ref={el => {
                                                this.newAnswer.current = el
                                                // We need these global refs so that SearchHeader and PanelHeader can focus here.
                                                if (activeMiddle) assignActiveNewAnswerRef(el)
                                                else if (isActive) assignSideNewAnswerRef(el)
                                            }}
                                            focusNewAnswer={this.focusNewAnswer}
                                            panel={this.props.panel}
                                            quest={quest}
                                            newAnswer={newAnswer}
                                            user={quest.user}
                                            showUsername={false}
                                            showUser={false}
                                            placeholder={'Write new message here...'}
                                            isTitle
                                            setShowPermsOptions={this.setShowPermsOptions}
                                            showPermsOptions={this.state.showPermsOptions}
                                            // The props below cause an infinite loop.
                                            filter={this.props.filter}
                                            active={this.props.isActive}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}

                {this.state.showQuestOptions && (
                    <div className={styles.questOptionsContainer}>
                        <QuestOptions
                            panel={this.props.panel}
                            quest={quest}
                            questPublic={this.state.questPublic}
                            questTeam={this.state.questTeam}
                            userTeams={gon.currentUser ? gon.currentUser.space_teams : null}
                            setQuestState={questState => this.setState(questState)}
                            answerFilter={this.state.filter || 'none'}
                            answerFilterOptions={answerFilterOptions}
                            deleteQuest={this.deleteQuest}
                            tweet={this.tweet}
                            showAnswerFilter={this.showAnswerFilter}
                            setAnswerFilter={this.setAnswerFilter}
                            showPermsOptions={this.state.showPermsOptions}
                            showFilterDropdown={this.state.showFilterDropdown}
                            showSortColDropdown={this.state.showSortColDropdown}
                            showExtendedOptions={this.state.showExtendedOptions}
                            showTypeOptions={this.state.showTypeOptions}
                            closeQuestOptions={this.closeQuestOptions}
                        />
                    </div>
                )}

                {(systemUser || !this.isDraft()) && (
                    <AnswerDndContainer
                        //key={quest.updated_at}
                        panelId={this.props.panelId}
                        displayAnswers={this.displayAnswers}
                        query={this.props.query}
                        forbidDrop={!!this.state.collapsePosition}
                        titleAnswerProps={titleAnswerProps}
                        systemUser={systemUser}
                        panel={this.props.panel}
                        filter={this.props.filter}
                        answerFilter={this.state.filter}
                        quest={this.props.quest}
                        removeAnswer={this.removeAnswer}
                        showInsert={!isBsv}
                        insertAnswer={this.insertAnswer}
                        updateAnswer={this.updateAnswer}
                        showBranching={this.props.panel === 'middle'}
                        showEmbedButton={this.props.showEmbedButton}
                        showAll={this.state.showAll}
                        moveAnswer={this.moveAnswer}
                        moveAnswerBetweenQuests={this.moveAnswerBetweenQuests}
                        resetOtherQuest={this.resetOtherQuest}
                        sideQuest={this.props.sideQuest}
                        isActive={isActive}
                        blog={isBlog}
                        answersShown={this.state.answersShown}
                        showVotes
                        questIx={this.props.ix}
                        isBsv={isBsv}
                    />
                )}

                {isActive && showStatsBottom && (
                    <div className="quest-stats-container">
                        <ExpandAnswers
                            moreAnswers={this.moreAnswers()}
                            expandAnswers={this.expandAnswers}
                            expandAnswersPartial={this.expandAnswersPartial}
                            filter={this.props.filter}
                            direction="up"
                            contextStyles={statsBorderStyle}
                        />
                    </div>
                )}

                {isActive && this.showNewAnswer() && (
                    <div className={styles.newAnswerPanel}>
                        {!!agentStatus && (
                            <NewAnswerStatus
                                showBorderBottom
                                status={agentStatus}
                                agentModel={agentModel}
                            />
                        )}

                        <div
                            className={cn(
                                styles.newAnswerContainer,
                                newAnswerContainerDesktopStyles,
                            )}
                        >
                            <NewAnswerDndWrapper
                                newAnswerRef={el => {
                                    this.newAnswer.current = el
                                    this.props.newAnswerRef.current = el
                                    if (activeMiddle) assignActiveNewAnswerRef(el)
                                    else if (isActive) assignSideNewAnswerRef(el)
                                }}
                                focusNewAnswer={this.focusNewAnswer}
                                panel={this.props.panel}
                                quest={this.props.quest}
                                newAnswer={newAnswer}
                                user={gon.currentUser}
                                showUser={true}
                                showUsername={false}
                                addAnswer={this.addAnswer}
                                appendAnswerToQuest={this.appendAnswerToQuest}
                                moveAnswerBetweenQuests={this.moveAnswerBetweenQuests}
                                blog={isBlog}
                                active={this.props.isActive}
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

function ExpandAnswers(props) {
    //console.log('expand answers', props.numMatchingAnswers)
    const moreAnswers = props.moreAnswers
    //console.log('border styles', props.contextStyles)
    const showPartialExpand = moreAnswers > 3 && !props.filter.notifications
    const borderStyles = showPartialExpand ? expandStyles.redBorder : null

    const numMatches = props.numMatchingAnswers
    const baseBannerText = props.query ? 'Match' : 'New'
    const bannerText = props.query && numMatches > 1 ? 'Matches' : baseBannerText

    return (
        <div className={cn(expandStyles.expandControlsComp, borderStyles, props.contextStyles)}>
            {!!props.numMatchingAnswers && (
                <div className={expandStyles.newAnswerBannerContainer}>
                    <div className={expandStyles.newAnswerBanner}>
                        {numMatches} {bannerText}
                    </div>
                </div>
            )}

            {showPartialExpand && (
                <WithTooltip tip={`Expand next ${Math.min(moreAnswers, 3)} messages.`}>
                    <div
                        className={expandStyles.expandControl}
                        onClick={props.expandAnswersPartial}
                    >
                        {<i className={`fa fa-long-arrow-${props.direction}`} />} Expand{' '}
                        <strong>{Math.min(moreAnswers, 3)}</strong>
                    </div>
                </WithTooltip>
            )}

            <WithTooltip tip={`Expand ${moreAnswers} remaining messages.`}>
                <div className={cn(expandStyles.expandControl)} onClick={props.expandAnswers}>
                    <i className="fa fa-expand" />
                    {!showPartialExpand && <span className={expandStyles.expandLabel}>Expand</span>}
                    All <strong>{moreAnswers}</strong>
                </div>
            </WithTooltip>
        </div>
    )
}

function NewAnswerStatus(props) {
    const status = props.status

    return (
        <div
            className={cn(
                styles.newMessageStatus,
                props.showBorderTop && styles.newMessageStatusBorderTop,
                props.showBorderBottom && styles.newMessageStatusBorderBottom,
            )}
        >
            <img className={cn('bot', styles.agentImage)} src={Bot} />

            {status === 'awaiting' && (
                <>
                    <span className={styles.agentName}>Tree</span>
                    <span>{`${props.agentModel} is ready.`}</span>
                </>
            )}

            {status === 'pending' && (
                <>
                    <span className={styles.agentName}>Tree</span>
                    <span>{`${props.agentModel} is thinking...`}</span>
                </>
            )}

            {status === 'error' && (
                <>
                    <span className={styles.agentName}>Tree</span>
                    <span>{`${props.agentModel} encountered an error.`}</span>
                </>
            )}
        </div>
    )
}

export default Connector(stateSelector, () => ({
    insertLeft: useInsertLeft(),
    insertRight: useInsertRight().insertRight,
    removeLeft: useRemoveLeft(),
    removeRight: useRemoveRight(),
    slideToPanel: useSlideToPanel(),
    panelContext: usePanelContext(),
}))(
    React.forwardRef((props, ref) => (
        <ErrorBoundary label="Quest">
            <Quest ref={ref} {...props} />
        </ErrorBoundary>
    )),
)
