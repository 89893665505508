import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import Answer from '../../answers/Answer'
import useStore from 'state/knovStore'
import cn from 'classnames'
import styles from './post-embed.module.scss'
import { isMobile } from 'react-device-detect'
import { isEmpty } from '../../../lib/value'
import useInsertRight from 'refactor/hooks/useInsertRight'
import useRemoveRight from 'refactor/hooks/useRemoveRight'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useGetAnswer from 'refactor/hooks/api/useGetAnswer'

export default React.memo(function PostEmbed(props) {
    const answer = useGetAnswer(props.answer?.id)
    const openConfirmMergeModal = useStore(
        state => state.actions.modalActions.openConfirmMergeModal,
    )
    const [showControls, setShowControls] = useState(false)
    const postContainerRef = useRef(null)
    const controlsContainerRef = useRef(null)

    const { insertRight } = useInsertRight()
    const removeRight = useRemoveRight()
    const { panelToRight } = usePanelContext()

    const deleteEmbed = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        props.delete(props.answer.id)
    }

    const onClick = async ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (answer.id === '__PRIVATE__') return

        const childQuestId = answer?.child_quests?.[0]?.id
        const rightQuestId = panelToRight()?.filter?.questId
        if (rightQuestId === childQuestId) {
            removeRight()
        } else {
            insertRight({ filter: { questId: childQuestId } })
        }
    }

    const openConfirm = ev => {
        ev.stopPropagation()
        ev.preventDefault()
        openConfirmMergeModal(props.containerQuest, props.answer.quest_id)
    }

    const onMouseEnter = ev => {
        if (isMobile) return false

        setShowControls(true)
        // Here we do the opposite entry function bc entering this component is equivalent to leaving the parent comp.
        if (props.onMouseLeave) props.onMouseLeave(ev)
    }

    const onMouseLeave = ev => {
        if (isMobile) return false

        setShowControls(false)
        // Here we do the opposite entry function bc entering this component is equivalent to leaving the parent comp.
        if (props.onMouseEnter) props.onMouseEnter(ev)
    }

    let delta_json, isRecursiveQuote
    const hasDeltaJson = !isEmpty(props.answer?.delta_json)
    if (hasDeltaJson) {
        delta_json = props.answer.delta_json
    } else {
        isRecursiveQuote = true
        delta_json = props.answer.nested_delta_json
    }

    const canDelete =
        gon.currentUser &&
        props.containerAnswer &&
        gon.currentUser.id === props.containerAnswer.user_id &&
        props.delete
    const showDelete = props.editMode && canDelete

    return (
        <div
            className={cn(styles.embedComp)}
            onMouseOver={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={postContainerRef}
            onClick={onClick}
        >
            <div className="parent-link">
                <div className="embed-main">
                    <div className="embed-header">
                        {false && props.label && (
                            <div className={cn(styles.parentLabel, 'parent-label pull-left')}>
                                {props.answer.tweet && (
                                    <div className={styles.twitterLabel}>
                                        <i className="fa fa-twitter"></i>
                                    </div>
                                )}
                                {isRecursiveQuote && (
                                    <span className={cn(styles.recursiveLabel)}>...</span>
                                )}
                                {props.label}
                            </div>
                        )}

                        {showDelete && (
                            <div className="delete-embed-btn pull-right" onClick={deleteEmbed}>
                                <i className="fa fa-close" />
                            </div>
                        )}
                        <div style={{ clear: 'both' }} />
                    </div>

                    {answer.id === '__PRIVATE__' ? (
                        <div className={styles.privateEmbed}>
                            <i className="fa fa-lock" />
                        </div>
                    ) : (
                        <Answer
                            isParent
                            isEmbed
                            answer={answer}
                            showInsert={false}
                            showControls={false}
                            showTopControls={false}
                            showVotes={false}
                            selectable={false}
                        />
                    )}
                </div>
            </div>

            <div className={styles.embedControlsContainer}>
                {false && (
                    <div
                        className={styles.embedControls}
                        onMouseEnter={() => {
                            if (!isMobile) setShowControls(true)
                        }}
                        onMouseLeave={() => {
                            if (!isMobile) setShowControls(false)
                        }}
                        ref={controlsContainerRef}
                    >
                        <div className="merge-btn" onClick={openConfirm}>
                            <i className="fa fa-sort" />
                            &nbsp; MERGE
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
})
