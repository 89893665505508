import React, { useState, useRef, useEffect } from 'react'
import styles from 'components/quests/ai-image-button.module.scss'
import cn from 'classnames'
import WithTooltip from 'components/shared/WithTooltip'
import { isMobile } from 'react-device-detect'
import usePanelContext from 'refactor/hooks/usePanelContext'

export const ImgGenerators = {
    DALLE: {
        humanName: 'DALL-E 3',
        description: "OpenAI's flagship image generator. Fast and high quality",
        actionTagName: 'dalle',
    },
    SD3: {
        humanName: 'SD3',
        description:
            'Stable Diffusion version 3. High quality images, with excellent text reproduction',
        actionTagName: 'sd3',
    },
    FLUX: {
        humanName: 'Flux Pro',
        description: 'Flux: generates high quality images, comparable to midjourney',
        actionTagName: 'flux',
    },
    FLUX_FAST: {
        humanName: 'Flux Fast',
        description: 'Faster version of Flux. Lower quality, but generates faster',
        actionTagName: 'fluxfast',
    },
    SD_LIGHTNING: {
        humanName: 'SD Lightning',
        description:
            'Lightning-fast version of Stable Diffusion. Lower quality, but generates faster',
        actionTagName: 'sdl',
    },
}

const defaultModel = ImgGenerators.DALLE.actionTagName

export default function AiImageButtonUIMain({
    models = Object.values(ImgGenerators).map(model => model.actionTagName),
    contextStyles,
    imageStyles,
    onClick,
    setModel,
    getEditor,
    activeModel = defaultModel,
    ...props
}) {
    const { hide } = usePanelContext()
    const [showMenu, setShowMenu] = useState(false)
    const [hover, setHover] = useState(false)
    const [menuDirection, setMenuDirection] = useState('up')
    const buttonRef = useRef(null)

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (showMenu && buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect()
            const spaceAbove = buttonRect.top
            const spaceBelow = window.innerHeight - buttonRect.bottom

            if (spaceBelow < 200 && spaceAbove > spaceBelow) {
                setMenuDirection('up')
            } else {
                setMenuDirection('down')
            }
        }
    }, [showMenu])

    const renderMenu = () => {
        return (
            <div
                className={cn(
                    styles.aiImageButtonMenu,
                    menuDirection === 'down' && styles.aiImageButtonMenuDown,
                )}
            >
                {models.map((model, index) => {
                    const modelInfo = Object.values(ImgGenerators).find(
                        m => m.actionTagName === model,
                    )
                    const activeModelStyles = activeModel === model ? styles.activeModel : null
                    return (
                        <WithTooltip tip={modelInfo.description} key={index}>
                            <div
                                className={cn(styles.aiImageButtonMenuItem, activeModelStyles)}
                                onClick={() => {
                                    const editor = getEditor()
                                    const actionTag = ` !${modelInfo.actionTagName}`
                                    const selection = editor.getSelection()
                                    if (selection) {
                                        editor.insertText(
                                            selection.index,
                                            actionTag,
                                            'bold',
                                            true,
                                            'api',
                                        )
                                        editor.setSelection(selection.index + actionTag.length)
                                    } else {
                                        editor.insertText(
                                            editor.getLength() - 1,
                                            actionTag,
                                            'bold',
                                            true,
                                            'api',
                                        )
                                        editor.setSelection(editor.getLength() - 1)
                                    }
                                    setShowMenu(false)
                                }}
                            >
                                {modelInfo.humanName}
                            </div>
                        </WithTooltip>
                    )
                })}
            </div>
        )
    }

    return (
        <div className={styles.aiImageButtonContainer}>
            <div className={cn(styles.aiImageButtonUIMainComp, contextStyles)} ref={buttonRef}>
                <WithTooltip tip="Insert an AI Image">
                    <div
                        className={cn(styles.toggleAiImageButton, hover && styles.hover)}
                        onClick={toggleMenu}
                        onMouseEnter={() => !isMobile && setHover(true)}
                        onMouseLeave={() => !isMobile && setHover(false)}
                    >
                        {/* toggle visibility instead of rendering due to weird loading artifact */}
                        <AiImageButtonIconSvg
                            className={styles.aiImgButtonIcon}
                            color={hover ? 'white' : 'currentColor'}
                        />
                    </div>
                </WithTooltip>

                <div className={cn(styles.separator, hover && styles.activeSeparator)} />

                <WithTooltip tip="Choose model">
                    <div
                        className={cn(styles.aiImageButtonModel, hover && styles.hover)}
                        onClick={toggleMenu}
                        onMouseEnter={() => !isMobile && setHover(true)}
                        onMouseLeave={() => !isMobile && setHover(false)}
                    >
                        <i className="fa fa-ellipsis-v" />
                    </div>
                </WithTooltip>
            </div>

            {showMenu && renderMenu()}
        </div>
    )
}

export function AiImageButtonIconSvg({
    color = 'currentColor',
    style = {},
    className = '',
    width = 24,
    height = 24,
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={style}
            className={className}
        >
            <defs />
            <g transform="matrix(-1,0,0,1,24.00387,0)">
                <path d="M 21.64,3.64 20.36,2.36 a 1.21,1.21 0 0 0 -1.72,0 L 2.36,18.64 a 1.21,1.21 0 0 0 0,1.72 l 1.28,1.28 a 1.2,1.2 0 0 0 1.72,0 L 21.64,5.36 a 1.2,1.2 0 0 0 0,-1.72" />
                <path d="m 14,7 3,3" />
                <path d="m 5,6 v 4" />
                <path d="m 19,14 v 4" />
                <path d="M 10,2 V 4" />
                <path d="M 7,8 H 3" />
                <path d="M 21,16 H 17" />
                <path d="M 11,3 H 9" />
            </g>
        </svg>
    )
}
