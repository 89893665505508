import React, { useState } from 'react'
import { setAccessToken, clearAccessTokens } from 'api/accessTokenMgmt'
import { Link, useNavigate } from 'react-router-dom'
import HeroImage from 'components/auth/HeroImage.jsx'
import flashStyles from 'components/shared/flash.module.scss'
import styles from 'components/auth/auth.module.scss'
import cn from 'classnames'

export default function Login() {
    const [success, setSuccess] = useState()
    const [message, setMessage] = useState('')
    const navigate = useNavigate()

    const urlParams = new URLSearchParams(window.location.search)
    const space_invite = urlParams.get('space_invite')
    const stream_invite = urlParams.get('stream_invite')

    let signupQuery = ''
    if (space_invite) {
        signupQuery = `?space_invite=${space_invite}`
    }
    if (stream_invite) {
        signupQuery = `?stream_invite=${stream_invite}`
    }

    async function onSubmit(ev) {
        ev.preventDefault()

        const email = ev?.target?.elements?.['sign-in-email-field']?.value
        const password = ev?.target?.elements?.['sign-in-pass-field']?.value

        const response = await fetch(`${window.knovApiUrl}/auth/sign_in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ email, password }),
        })

        if (response.ok) {
            const tokenHeaders = extractTokenHeaders(response)
            authUser(tokenHeaders)
        } else {
            const data = await response.json()
            const errorMessage = data?.errors?.[0] || 'Authentication error.'
            setSuccess(false)
            setMessage(errorMessage)
        }
    }

    const handlePandaLogin = async () => {
        if (!window.panda) {
            alert(
                'Panda Wallet is not available. Please ensure the Panda Wallet extension is installed and try again.',
            )
            return
        }

        const pubkey = await panda.connect()
        // use panda to sign a message, and sent the signed msg + the pubkey to the server
        // server can then verify the sig, and then give us tokens
        // we include the date in the signed msg to be checked on the backend to prevent reuse of creds / replay attacks
        const msg = `Authorize Treechat login on ${new Date().toLocaleDateString()} at ${new Date().toLocaleTimeString()} (${Math.floor(
            new Date().getTime() / 1000,
        )})`
        const pandaSignedMsg = await panda.signMessage({ message: msg })

        // retrieve user object using panda pubkey
        const userResponse = await fetch(`${window.knovApiUrl}/api/v1/users/by-panda-pubkey`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pubkey, sig: pandaSignedMsg?.sig, msg }),
        })

        if (userResponse.ok) {
            const { user } = await userResponse.json()
            const tokenHeaders = extractTokenHeaders(userResponse)
            authUser(tokenHeaders)
        } else {
            alert('Failed to retrieve user information. Please try again.')
        }
    }

    // extract token from response headers for token auth
    function extractTokenHeaders(response) {
        return {
            'access-token': response.headers.get('access-token'),
            expiry: response.headers.get('expiry'),
            uid: response.headers.get('uid'),
            client: response.headers.get('client'),
        }
    }

    // authenticate user with token headers
    function authUser(tokenHeaders) {
        clearAccessTokens()
        setAccessToken(tokenHeaders)
        setSuccess(true)
        setMessage('Successfully logged in.')

        const key = 'attempted_path'
        const attemptedPath = sessionStorage.getItem(key)
        sessionStorage.removeItem(key)
        if (
            attemptedPath &&
            !attemptedPath.startsWith('/login') &&
            !attemptedPath.startsWith('/signup')
        )
            navigate(attemptedPath, { replace: true })
        else navigate('/', { replace: true })
    }

    return (
        <div className="sessions_new landing">
            <div className="earth container-fluid">
                <div className="row">
                    <HeroImage />
                </div>
                <div className="sign-in-form col-md-offset-2 col-md-8" data-test-id="sign-in">
                    <div className={styles.statusContainer}>
                        <div className={success ? flashStyles.success : flashStyles.error}>
                            {message}
                        </div>
                    </div>

                    <form id="new-session" onSubmit={onSubmit}>
                        <div className="sign-in-fields">
                            <div className="field">
                                <span className="field-label">Email</span>
                                <input
                                    style={{
                                        fontSize: /* this prevents autozoom on mobile */ '16px',
                                    }}
                                    type="email"
                                    placeholder=""
                                    className="form-control"
                                    autoComplete="email"
                                    autoFocus={true}
                                    id="sign-in-email-field"
                                />
                            </div>
                            <div className="field">
                                <span className="field-label">Password</span>
                                <input
                                    style={{
                                        fontSize: /* this prevents autozoom on mobile */ '16px',
                                    }}
                                    type="password"
                                    placeholder=""
                                    className="form-control"
                                    autoComplete="current-password"
                                    id="sign-in-pass-field"
                                />
                            </div>
                            <div className={styles.signupContainer}>
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-lg sign-in-btn"
                                >
                                    Log In
                                </button>
                            </div>

                            <div className={styles.or}>OR</div>

                            <div className={styles.signupContainer}>
                                <div
                                    className={cn(
                                        styles.walletChoiceBtn,
                                        styles.pandaWallet,
                                        'btn-lg',
                                    )}
                                    onClick={handlePandaLogin}
                                >
                                    <img
                                        src="/pandawalletlogo.png"
                                        alt="Panda Wallet Logo"
                                        className={styles.walletLogo}
                                    />
                                    <div className={styles.walletName}>
                                        Log in with Panda Wallet
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="auth-links">
                        {"Don't have an account?"}
                        <Link className="devise-shared-signup" to={`/signup${signupQuery}`}>
                            {' '}
                            Sign up{' '}
                        </Link>
                    </div>

                    <div className="auth-links">
                        {'Forgot password?'}
                        <Link className="devise-shared-signup" to="/auth/password/request-reset">
                            {' '}
                            Reset Password{' '}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
