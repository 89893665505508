const SATOSHIS = 100000000
const DEFAULT_QUICK_BSV_LOCK_SATS = 1000000
const DEFAULT_QUICK_BSV_LOCK_BLOCKS = 100
const BITCOIN_UNIT = 'bitcoin'
const SATOSHI_UNIT = 'satoshi'
const DEFAULT_BSV_UNIT = BITCOIN_UNIT

const toSats = (bitcoin: number) => bitcoin * SATOSHIS
const toBitcoin = (sats: number) => sats / SATOSHIS

const format = (big: number) => {
    let formattedSats = ''
    if (big >= 1000000) {
        formattedSats = big / 1000000 + 'M'
    } else if (big >= 1000) {
        formattedSats = big / 1000 + 'K'
    } else {
        formattedSats = big.toString()
    }
    // Remove trailing zeros after the decimal point
    return formattedSats.replace(/\.0+([KM])$/, '$1')
}

export {
    SATOSHIS,
    DEFAULT_QUICK_BSV_LOCK_SATS,
    DEFAULT_QUICK_BSV_LOCK_BLOCKS,
    BITCOIN_UNIT,
    SATOSHI_UNIT,
    DEFAULT_BSV_UNIT,
    toSats,
    toBitcoin,
    format,
}
