import useStore from 'state/knovStore'
import { uniqBy } from 'lodash'
import { getCenterPanel, getRightPanel } from 'state/imperativeApis/swiperApi'
import questModel from 'components/quests/questModel'
import { getCachedQuest } from 'state/cache'
import { isMobile } from 'react-device-detect'

export type Filter = {
    space_id: string
    questId?: string
    questIds?: string[]
    notifications?: boolean
    private?: boolean
    clip?: boolean
    public_clip?: boolean
    public?: boolean
    hodlocker?: boolean
    treechat?: boolean
    link?: boolean
    all?: boolean
    user?: string
    public_user?: string
    team_ids?: string[]
    history?: boolean
    starred?: boolean
    meme?: string
    order?: OrderType<Filter> // some orders (locked and unlocked only apply for public streams)
    query?: string
    answerId?: string
}

type SharedOrderTypes =
    | 'vote'
    | 'lockvalue'
    | 'relev'
    | 'semantic'
    | 'time'
    | 'hot'
    | 'new'
    | 'random'
type OrderType<T> = T extends { public: true }
    ? SharedOrderTypes | 'locked' | 'unlocked'
    : SharedOrderTypes

const clearStream = filter => {
    let newFilter = { ...filter }
    delete newFilter.questId
    delete newFilter.notifications
    delete newFilter.private
    delete newFilter.clip
    delete newFilter.public_clip
    delete newFilter.public
    delete newFilter.hodlocker
    delete newFilter.twetch
    delete newFilter.treechat
    delete newFilter.people
    delete newFilter.participants
    delete newFilter.link
    delete newFilter.all
    delete newFilter.user
    delete newFilter.team_ids
    delete newFilter.page
    delete newFilter.history
    delete newFilter.starred
    delete newFilter.clear
    delete newFilter.people

    return newFilter
}

function availableTeams(user, publicTeams) {
    let teams = []
    if (user) {
        teams = uniqBy([...user.space_teams, ...(publicTeams || [])], 'id')
    }
    return teams
}

export function pathFromFilter(filter) {
    const team = gon.currentUser
        ? useStore
              .getState()
              .userStreams?.find(team => filter.team_ids && team.id === filter?.team_ids[0])
        : null

    const user = filter.user ? useStore.getState().users?.find(u => u.id === filter.user) : null

    let path
    if (filter.questId) {
        path = '/quest/' + filter.questId
    } else if (team) {
        path = team.path
    } else if (user) {
        path = user.path
    } else if (filter.private) {
        path = '/stream/private'
    } else if (filter.clip) {
        path = '/stream/clips'
    } else if (filter.public_clip) {
        path = '/stream/public-clips'
    } else if (filter.all) {
        path = '/stream/all'
    } else if (filter.hodlocker) {
        path = '/stream/hodlocker'
    } else if (filter.twetch) {
        path = '/stream/twetch'
    } else if (filter.treechat) {
        path = '/stream/treechat'
    } else if (filter.public) {
        path = '/stream/public'
    } else if (filter.people) {
        path = '/stream/people'
    } else if (filter.participants) {
        path = '/people/' + filter.participants
    } else if (filter.notifications) {
        path = '/stream/notifications'
    } else if (filter.link) {
        path = '/stream/link-access'
    } else if (filter.history) {
        path = '/stream/history'
    } else if (filter.starred) {
        path = '/stream/starred'
    } else {
        path = ''
    }
    return path
}

export function ensureEmptyEnds() {
    const panels = useStore.getState().panels
    const firstPanel = panels.state[0]
    const lastPanel = panels.state[panels.state.length - 1]
    if (!firstPanel?.empty) {
        panels.prependEmptyPanel()
    } else if (!lastPanel?.empty) {
        panels.appendEmptyPanel()
    }
}

export function insertCenter(newFilter) {
    const panels = useStore.getState().panels
    if (isMobile) {
        const panelId = getCenterPanel()?.panelId
        panels.insertPanelRight(panelId, { filter: newFilter, empty: false }, { animate: false })
        ensureEmptyEnds()
    } else {
        const panelId = getRightPanel()?.panelId
        panels.insertPanelLeft(panelId, { filter: newFilter, empty: false }, { animate: true })
    }

    if (newFilter.answerId) {
        //TODO
    }
}

export default function useStreamFilter(
    panelId = getCenterPanel()?.panelId,
    opts = { append: true },
) {
    // Default to center panel.
    const getFilter = () => useStore.getState().panels.getPanel(panelId)?.filter
    let filter = getFilter()
    if (filter?.questId) {
        const quest = getCachedQuest(filter.questId)
        //console.log('USE STREAM FILTER', quest)
        if (quest) {
            const perms = questModel.getFilter(quest)
            //console.log('USE STREAM FILTER PERMS', perms)
            filter = { ...clearStream(filter), ...perms }
        }
    }
    const editPanel = useStore.getState().panels.editPanel
    const appendSession = useStore.getState().panels.appendSession

    const selectNewFilter = (newFilter = {}) => {
        if (opts.append) {
            // TODO HACK... we're hijacking the append option here to insert instead, need to refactor to be able to specify particular actions maybe in a panelAction.js api that consolidates state.panels and swiperApi actions.
            //appendSession(newFilter, { centerPanel: true })
            insertCenter(newFilter)
        } else if (opts.insertAndCenter) {
            insertCenter(newFilter)
        } else {
            editPanel(panelId, { filter: newFilter, empty: false })
            ensureEmptyEnds()
        }
    }

    const selectPeople = (peopleFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...peopleFilter, people: true }
        selectNewFilter(newFilter)
    }

    const selectHistory = (historyFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...historyFilter, history: true }
        selectNewFilter(newFilter)
    }

    const selectStarred = (starredFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...starredFilter, starred: true }
        selectNewFilter(newFilter)
    }

    const selectNotifications = (notiFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...notiFilter, notifications: true }
        selectNewFilter(newFilter)
    }

    const selectPrivate = (privateFilter = {}) => {
        console.log('SELECT PRIVATE', panelId, getFilter(panelId))
        const newFilter = { ...clearStream(filter), ...privateFilter, private: true }
        selectNewFilter(newFilter)
    }

    const selectClips = (clipFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...clipFilter, clip: true }
        selectNewFilter(newFilter)
    }

    const selectPublicClips = (publicClipFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...publicClipFilter, public_clip: true }
        selectNewFilter(newFilter)
    }

    const selectPublic = (publicFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...publicFilter, public: true }
        selectNewFilter(newFilter)
    }

    const selectTreechat = (treechatFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...treechatFilter, treechat: true }
        selectNewFilter(newFilter)
    }

    const selectHodlocker = (hodlockerFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...hodlockerFilter, hodlocker: true }
        selectNewFilter(newFilter)
    }

    const selectTwetch = (twetchFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...twetchFilter, twetch: true }
        selectNewFilter(newFilter)
    }

    const selectLinks = (linkFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...linkFilter, link: true }
        selectNewFilter(newFilter)
    }

    const selectAll = (allFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...allFilter, all: true }
        selectNewFilter(newFilter)
    }

    const selectUser = (userId, userFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...userFilter, user: userId }
        selectNewFilter(newFilter)
    }

    const selectParticipants = (participantsKey, participantsFilter = {}) => {
        const newFilter = {
            ...clearStream(filter),
            ...participantsFilter,
            participants: participantsKey,
        }
        selectNewFilter(newFilter)
    }

    const selectTeam = (teamId, teamFilter = {}) => {
        const newFilter = { ...clearStream(filter), ...teamFilter, team_ids: [teamId] }
        selectNewFilter(newFilter)
    }
    const teams = availableTeams(gon?.currentUser, useStore.getState().publicTeams)
    const filterTeamId = filter && filter.team_ids && filter.team_ids[0]

    const isNotiSelected = () => filter?.notifications
    const isPrivateSelected = () => filter?.private
    const isClipSelected = () => filter?.clip
    const isPublicClipSelected = () => filter?.public_clip
    const isPublicSelected = () => filter?.public
    const isTreechatSelected = () => filter?.treechat
    const isHodlockerSelected = () => filter?.hodlocker
    const isTwetchSelected = () => filter?.twetch
    const isLinkSelected = () => filter?.link
    const isAllSelected = () => filter?.all
    const isUserSelected = () => filter?.user
    const isTeamSelected = teamId => !!filter?.team_ids && filter?.team_ids.includes(teamId)
    const isParticipantsSelected = participantsKey =>
        filter?.participants && filter?.participants === participantsKey
    const isHistorySelected = () => filter?.history
    const isStarredSelected = () => filter?.starred
    const isPeopleSelected = () => filter?.people

    const setStreamOrder = order => {
        editPanel(panelId, { filter: { ...filter, order } })
    }

    const setQuery = (query, order) => {
        editPanel(panelId, { filter: { ...filter, query, ...(order && { order }) } })
    }

    return {
        filter,
        selectNewFilter,
        selectNotifications,
        selectPrivate,
        selectClips,
        selectPublicClips,
        selectTreechat,
        selectHodlocker,
        selectTwetch,
        selectUser,
        appendSession,

        selectPublic,
        selectLinks,
        selectAll,
        selectTeam,
        selectHistory,
        isHistorySelected,
        selectStarred,
        isStarredSelected,
        selectPeople,
        isPeopleSelected,
        selectParticipants,
        isParticipantsSelected,
        teams,
        filterTeamId,
        setStreamOrder,
        setQuery,
        isNotiSelected,
        isPrivateSelected,
        isPublicSelected,
        isTreechatSelected,
        isHodlockerSelected,
        isTwetchSelected,
        isLinkSelected,
        isClipSelected,
        isPublicClipSelected,
        isAllSelected,
        isUserSelected,
        isTeamSelected,
    }
}
