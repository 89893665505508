import { useQuery } from '@tanstack/react-query'
import api from 'api/api'
import { Quest } from 'types/quests'
import { QuestId } from 'state/PanelState'
import { cacheQuest, getCachedQuest } from 'state/cache'

type Options = {
    cacheOnly?: boolean
}

export default function useGetQuest(id: QuestId | undefined, options: Options = {}) {
    // Check if quest is a draft.
    const cachedQuest = id ? getCachedQuest(id) : null
    const enabled = !!id && !cachedQuest?.is_draft

    const result = useQuery({
        queryKey: ['quest', id],

        queryFn: async () => {
            const quest = id ? await api.getQuestQuery(id) : null
            if (quest) {
                return cacheQuest(quest)
            } else {
                return null
            }
        },

        enabled,
        staleTime: Infinity,
        gcTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        structuralSharing: false,
        networkMode: 'always',
    })

    return {
        ...result,
        quest: result.data ? getCachedQuest(result.data?.id) : null,
    }
}
