import React from 'react'
import styles from 'components/answers/thread-info.module.scss'
import { LockAmount } from 'components/answers/LockControl'
import questModel from 'components/quests/questModel'
import ErrorBoundary from 'components/shared/ErrorBoundary'

export default function ThreadInfo({ quest, query }) {
    const matchingAnswers = questModel.matchingAnswers(quest)
    const tailAnswers = questModel.showUnarchivedAnswers(quest)
    const threadLockedSats = tailAnswers?.reduce((acc, ans) => acc + ans?.bsv_lock_amt, 0)
    const existNewAnswers = tailAnswers.some(
        answer => answer.created_at >= quest.last_action_created_at,
    )

    return (
        <ErrorBoundary>
            <div className={styles.threadInfoComp}>
                <div className={styles.leftContainer}>
                    {threadLockedSats > 0 && (
                        <div className={styles.amountContainer}>
                            <LockAmount lockedSats={threadLockedSats} />
                        </div>
                    )}

                    <div className={styles.counts}>
                        {query && matchingAnswers?.length > 0 && <MatchingAnswers quest={quest} />}
                        {existNewAnswers && <NewAnswers quest={quest} />}
                        {tailAnswers?.length > 0 && !existNewAnswers && (
                            <LastAnswers quest={quest} maxNumLast={3} />
                        )}
                        <Replies quest={quest} />
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    )
}

function MatchingAnswers({ quest }) {
    const matchingAnswers = questModel.matchingAnswers(quest)
    return (
        <div className={styles.matchingAnswers}>
            <span className={styles.count}>{matchingAnswers?.length}</span>
            <span className={styles.label}>
                match{matchingAnswers?.length === 1 ? '' : 'es'} of
            </span>
        </div>
    )
}

function NewAnswers({ quest }) {
    const newAnswers = questModel
        .showUnarchivedAnswers(quest)
        .filter(a => a.created_at >= quest.last_action_created_at)
    return (
        <div className={styles.newAnswers}>
            <span className={styles.count}>{newAnswers?.length}</span>
            <span className={styles.label}>new of</span>
        </div>
    )
}

function LastAnswers({ quest, maxNumLast }) {
    const latestAnswers = questModel.showUnarchivedAnswers(quest)
    return (
        <div className={styles.newAnswers}>
            <span className={styles.count}>{Math.min(maxNumLast, latestAnswers?.length)}</span>
            <span className={styles.label}>latest of</span>
        </div>
    )
}

function Replies({ quest }) {
    const tailAnswers = questModel.showUnarchivedAnswers(quest)
    return (
        <div className={styles.replies}>
            <span className={styles.count}>{tailAnswers?.length}</span>
            <span className={styles.label}>{tailAnswers?.length === 1 ? 'reply' : 'replies'}</span>
        </div>
    )
}
