import React from 'react'
import ReactDOM from 'react-dom'
import styles from 'components/answers/lock-control.module.scss'
import LockVote from 'components/votes/LockVote'
import { SATOSHIS } from 'lib/bsv-util'
import SatsIcon from 'components/shared/SatsIcon'
import cn from 'classnames'
import api from 'api/api'
import useStore from 'state/knovStore'
import useStreamFilters from 'components/filters/useStreamFilters'
import usePanelContext from 'refactor/hooks/usePanelContext'

export default function LockControl({ answer }) {
    const postLockRef = React.useRef<HTMLDivElement>(null)
    const lockedSats = parseInt(answer.bsv_lock_amt) || 0

    const [showPopover, _setShowPopover] = React.useState(false)
    const setShowPopover = async val => {
        _setShowPopover(val)
        if (val) {
            // to make sure lock content is fresh
            await api.getAnswer(answer.id)
        }
    }

    return (
        <div className={styles.bsvLockContainer}>
            <div ref={postLockRef} className={styles.bsvLockAmountContainer}>
                <>
                    <div
                        onClick={ev => {
                            ev.stopPropagation()
                            setShowPopover(true)
                        }}
                    >
                        <LockAmount lockedSats={lockedSats} />
                    </div>

                    {showPopover &&
                        ReactDOM.createPortal(
                            <BsvLocks
                                answer={answer}
                                postLockRef={postLockRef}
                                setShowPopover={setShowPopover}
                            />,
                            document.body,
                        )}
                </>
            </div>

            <LockVote postLockRef={postLockRef} answer={answer} />
        </div>
    )
}

function LockAmount({ lockedSats }) {
    return (
        <div className={styles.lockAmount}>
            {lockedSats > 0 && lockedSats / SATOSHIS > 1 && (
                <div className={styles.bitcoinBar}>
                    <span className={cn(styles.bitcoinSymbol, !lockedSats ? styles.dormant : null)}>
                        <i className="fa fa-btc" style={{ marginRight: '2px' }}></i>
                    </span>

                    <div
                        className={cn(
                            styles.lockVoteAmount,
                            styles.bitcoin,
                            !lockedSats ? styles.dormant : null,
                        )}
                    >
                        <NumberWithSpaces number={Math.floor(lockedSats / SATOSHIS)} px={2} />
                    </div>
                </div>
            )}

            {lockedSats > 0 && lockedSats % SATOSHIS > 0 && (
                <div className={styles.satsBar}>
                    <SatsIcon
                        contextStyles={cn(
                            styles.satsSymbol,
                            !lockedSats ? styles.dormantSatsSymbol : null,
                        )}
                    />

                    <div
                        className={cn(
                            styles.lockVoteAmount,
                            styles.sats,
                            !lockedSats ? styles.dormant : null,
                        )}
                    >
                        <>
                            <NumberWithSpaces number={lockedSats % SATOSHIS} px={2} />
                        </>
                    </div>
                </div>
            )}

            {lockedSats === 0 && (
                <div className={styles.emptyBar}>
                    <SatsIcon contextStyles={cn(styles.satsSymbol, styles.dormantSatsSymbol)} />

                    <div className={cn(styles.lockVoteAmount, styles.sats, styles.dormant)}>0</div>
                </div>
            )}
        </div>
    )
}

function BsvLocks({ answer, postLockRef, setShowPopover }) {
    return (
        <div
            className={styles.popover}
            ref={node => {
                if (node && postLockRef.current) {
                    const rect = postLockRef.current.getBoundingClientRect()
                    node.style.top = `${rect.bottom + 5 + window.scrollY}px`
                    node.style.left = `${rect.left + window.scrollX}px`
                }
            }}
        >
            <div
                onClick={e => {
                    e.stopPropagation()
                    setShowPopover(false)
                }}
                className={styles.closeButton}
            >
                &#215;{/* <-- an '×' */}
            </div>
            <p>🔒 {answer?.bsv_locks?.length} locks</p>
            <ul>
                {answer?.bsv_locks?.map((lock, index) => (
                    <li key={index}>
                        <a
                            href={`https://whatsonchain.com/tx/${lock.tx_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={ev => ev.stopPropagation()}
                        >
                            <i className="fa fa-cube"></i>
                        </a>
                        <UserHandleFromId
                            userId={lock.user_id}
                            afterClick={() => setShowPopover(false)}
                        />
                        locked {Number(lock?.amount)?.toLocaleString()} sats for{' '}
                        {lock?.block_height - lock?.locked_at_block} blocks.
                    </li>
                ))}
            </ul>
        </div>
    )
}

const UserHandleFromId = ({ userId, afterClick }) => {
    const users = useStore(state => state.users)
    const { panel } = usePanelContext()
    const { selectUser } = useStreamFilters(panel?.id, { append: true })
    const onSelectUser = ev => {
        ev.preventDefault()
        ev.stopPropagation()
        selectUser(userId)
        afterClick()
    }
    const user = users?.find(user => user.id === userId)
    return <a onClick={onSelectUser}>@{user?.name}</a>
}

const NumberWithSpaces = ({ number, px }) => {
    let numberParts: string[] = []
    if (number === 0) {
        numberParts.push('0')
    } else {
        while (number > 0) {
            let part = number % 1000
            numberParts.unshift(part.toString().padStart(3, '0'))
            number = Math.floor(number / 1000)
        }
    }

    if (numberParts.length >= 1) {
        numberParts[0] = numberParts[0].replace(/^0+/, '')
    }

    return (
        <span>
            {numberParts.map((part, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <span style={{ marginRight: `${px}px` }}></span>}
                    <span>{part}</span>
                </React.Fragment>
            ))}
        </span>
    )
}

export { NumberWithSpaces, LockAmount }
