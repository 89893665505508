import React, { useState, useRef, useEffect } from 'react'
import styles from 'components/quests/knov-agent-button.module.scss'
import Bot from 'assets/knov-answer.svg'
import WhiteBot from 'assets/knov-white.svg'
import cn from 'classnames'
import WithTooltip from 'components/shared/WithTooltip'
import { isMobile } from 'react-device-detect'
import usePanelContext from 'refactor/hooks/usePanelContext'

export enum LLMModels {
    GPT_4_O = 'gpt-4o',
    CLAUDE_SONNET = 'claude-sonnet3.5',
    GPT_4 = 'gpt-4',
    GROQ_LLAMA3 = 'groq-llama3',
    LLAMA31_405B = 'llama-3.1-405b',
    MIXTRAL = 'mixtral',
    // MIXTRAL_DOLPHIN = 'mixtral-dolphin',
    MIXTRAL_UNCENSORED = 'mixtral-uncensored',
    // CLAUDE = 'claude',
    // CLAUDE_2 = 'claude-2',
    // CLAUDE_INSTANT = 'claude-instant',
    CLAUDE_OPUS = 'claude-opus',
    // CLAUDE_HAIKU = 'claude-haiku',
    GPT_3_5 = 'gpt-3.5-turbo',
}

export const defaultModel = LLMModels.GPT_4_O

export default function KnovAgentButtonUIMain({
    models = Object.values(LLMModels),
    contextStyles,
    active,
    imageStyles,
    onClick,
    setModel,
    activeModel = defaultModel,
    ...props
}) {
    const { hide } = usePanelContext()
    const [showMenu, setShowMenu] = useState(false)
    const [agentHover, setAgentHover] = useState(false)
    const [modelHover, setModelHover] = useState(false)
    const [menuDirection, setMenuDirection] = useState('up')
    const buttonRef = useRef(null)

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (showMenu && buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect()
            const spaceAbove = buttonRect.top
            const spaceBelow = window.innerHeight - buttonRect.bottom

            if (spaceBelow < 200 && spaceAbove > spaceBelow) {
                setMenuDirection('up')
            } else {
                setMenuDirection('down')
            }
        }
    }, [showMenu])

    const renderMenu = () => {
        return (
            <div
                className={cn(
                    styles.knovAgentMenu,
                    menuDirection === 'down' && styles.knovAgentMenuDown,
                )}
            >
                {models.map((model, index) => {
                    const activeModelStyles = activeModel === model ? styles.activeModel : null
                    return (
                        <div
                            key={index}
                            className={cn(styles.knovAgentMenuItem, activeModelStyles)}
                            onClick={() => {
                                setModel(model)
                                setShowMenu(false)
                            }}
                        >
                            {model}
                        </div>
                    )
                })}
            </div>
        )
    }

    const activeAgentStyles = active || agentHover ? styles.active : null
    const activeModelStyles = active || modelHover ? styles.active : null

    return (
        <div className={styles.knovAgentButtonContainer}>
            <div
                className={cn(
                    styles.knovAgentButtonUIMainComp,
                    contextStyles,
                    active && styles.active,
                )}
                ref={buttonRef}
            >
                <WithTooltip tip={active ? 'Remove agent.' : 'Invite agent.'}>
                    <div
                        className={cn(styles.toggleAgent, agentHover && styles.hover)}
                        onClick={onClick}
                        onMouseEnter={() => !isMobile && setAgentHover(true)}
                        onMouseLeave={() => !isMobile && setAgentHover(false)}
                    >
                        {/* toggle visibility instead of rendering due to weird loading artifact */}
                        <img
                            className={cn(
                                'white-bot',
                                styles.agentImage,
                                imageStyles,
                                activeAgentStyles && !hide && styles.visible,
                            )}
                            src={WhiteBot}
                        />

                        <img
                            className={cn(
                                'bot',
                                styles.agentImage,
                                imageStyles,
                                !activeAgentStyles && !hide && styles.visible,
                            )}
                            src={Bot}
                        />
                    </div>
                </WithTooltip>

                <div
                    className={cn(
                        styles.separator,
                        (active || agentHover || modelHover) && styles.activeSeparator,
                    )}
                />

                <WithTooltip tip="Choose agent model.">
                    <div
                        className={cn(styles.knovAgentModel, activeModelStyles)}
                        onClick={toggleMenu}
                        onMouseEnter={() => !isMobile && setModelHover(true)}
                        onMouseLeave={() => !isMobile && setModelHover(false)}
                    >
                        <i className="fa fa-ellipsis-v" />
                    </div>
                </WithTooltip>
            </div>

            {showMenu && renderMenu()}
        </div>
    )
}
