import { v4 as uuid } from 'uuid'
import { Quest, QuestPerms } from 'types/quests'
import answerModel from 'components/answers/answerModel'
import useStore from 'state/knovStore'
import { getCachedQuest, cacheQuest } from 'state/cache'
import queryClient from 'api/queryClient'

const model = {
    isPrivate: quest => quest.private && !quest.team_id,

    isPublic: quest => quest.public && !quest.private && !quest.team_id,

    isLinkShare: quest => !quest.public && !quest.private && !quest.team_id,

    hasTeam: quest => !!quest.team,

    getFilter: quest => {
        if (model.isPrivate(quest)) {
            return { private: true }
        } else if (model.isPublic(quest)) {
            return { public: true }
        } else if (model.isLinkShare(quest)) {
            return { link: true }
        } else if (model.hasTeam(quest)) {
            if (quest.team_id === gon.HODLOCKER_TEAM_ID) {
                return { hodlocker: true }
            } else if (quest.team_id === gon.TWETCH_TEAM_ID) {
                return { twetch: true }
            } else {
                return { team_ids: [quest.team_id] }
            }
        }
    },

    sortedQuestAnswers: quest => {
        const col = quest.default_sort_col || 'position'
        const dir = quest.default_sort_dir || 'asc'
        let answers
        if (col === 'position' && dir === 'asc') {
            answers = model.positionAnswers(quest.sorted_answers)
        } else if (col === 'position' && dir === 'desc') {
            answers = model.positionAnswers(quest.sorted_answers).reverse()
        } else if (col === 'vote' && dir === 'desc') {
            answers = model.votedAnswers(quest.sorted_answers)
        } else {
            answers = model.votedAnswers(quest.sorted_answers).reverse()
        }
        return answers
    },

    positionAnswers: answers =>
        answers ? [...answers].sort((a, b) => a.position - b.position) : [],

    filterUnarchivedAnswers: answers => (answers ? answers.filter(ans => !ans?.archive) : []),

    votedAnswers: answers => {
        return answers
            ? [...answers].sort((a, b) => {
                  const av = a.vote_total
                  const bv = b.vote_total
                  const acv = a.crypto_vote_total
                  const bcv = b.crypto_vote_total
                  if (acv != bcv) return bcv - acv
                  const order = bv - av
                  return order === 0 ? a.position - b.position : order
              })
            : []
    },

    matchingAnswers: quest => {
        return quest?.sorted_answers && quest?.matching_answer_ids
            ? quest.sorted_answers.filter(ans => quest.matching_answer_ids.includes(ans.id))
            : []
    },

    matchingMatchingAnswers: quest => {
        return model
            .matchingAnswers(quest)
            .sort(
                (a, b) =>
                    quest.matching_answer_ids.indexOf(a.id) -
                    quest.matching_answer_ids.indexOf(b.id),
            )
    },

    showUnarchivedAnswers: quest => model.filterUnarchivedAnswers(model.sortedQuestAnswers(quest)),

    threadType: quest => {
        let type
        if (quest.thread_type === 'chatgpt') {
            type = {
                name: 'Knov GPT',
                icon: null,
                desc: 'This is a Knov GPT chatbot thread.',
            }
        } else if (quest.default_sort_col === 'vote') {
            type = {
                name: 'Quest',
                icon: 'fa-sort-amount-desc',
                desc: 'This is a Quest thread with messages sorted by Upvalue.',
            }
        } else if (quest.blog) {
            type = {
                name: 'Post',
                icon: 'fa-file',
                desc: 'This is a Post whose messages are written by a single author.',
            }
        } else {
            type = {
                name: 'Thread',
                icon: 'fa-list-ul',
                desc: 'This is a Conversation thread with potentially multiple participants.',
            }
        }
        return type
    },

    getPerms: (quest: Quest): QuestPerms => {
        return {
            space_id: quest.space_id,
            team_id: quest.team_id,
            team: quest.team,
            private: quest.private,
            public: quest.public,
        }
    },

    permsFromFilter: (filter: { [key: string]: any }): QuestPerms => {
        let perms: QuestPerms = {
            team_id: null,
            team: null,
            private: null,
            public: null,
        }
        if (filter.questId) {
            const quest = getCachedQuest(filter.questId)
            perms = model.getPerms(quest)
        } else if (
            filter.private ||
            filter.notifications ||
            filter.all ||
            filter.clip ||
            filter.history ||
            filter.starred ||
            filter.user
        )
            perms.private = true
        // Set null if undefined so it's correctly stringified.
        else if (filter.team_ids?.length) {
            perms.team_id = filter.team_ids[0]
            // Make sure team is in user's space.
            const user = gon.currentUser
            const team = perms.team_id
                ? user.space_teams && user.space_teams.find(t => perms.team_id === t.id)
                : null
            if (team) perms.team = team
            else perms.team_id = null
        } else if (filter.public || filter.treechat || filter.hodlocker) perms.public = true

        return perms
    },

    newQuest: (quest: Partial<Quest>): Quest => {
        const questId = uuid()

        let localQuest: Partial<Quest> = {
            id: questId,
            is_draft: true,
            ...quest,
        }

        if (!localQuest.space_id) useStore.getState().getSpaceId()
        const now = new Date()
        if (!localQuest.created_at) localQuest.created_at = now
        if (!localQuest.updated_at) localQuest.updated_at = now
        if (!localQuest.path) localQuest.path = `/quest/${questId}`

        if (!localQuest.user_id) localQuest.user_id = gon.currentUser?.id
        if (!localQuest.user) localQuest.user = gon.currentUser

        if (!localQuest.parent_id) {
            localQuest.parent = answerModel.newAnswer({
                is_draft: true,
                user_id: localQuest.user_id,
                user: localQuest.user,
                child_quests: [
                    {
                        id: questId,
                    },
                ],
            })
            localQuest.parent_id = localQuest.parent.id
        }

        if (!localQuest.sorted_answers) localQuest.sorted_answers = []
        return localQuest as Quest
    },

    addDraftAnswer: (quest: Quest, draftAnswer: Answer) => {
        quest.draft = draftAnswer
    },

    newQuestFromFilter: (quest: Partial<Quest>, filter: object): Quest => {
        const perms = model.permsFromFilter(filter)

        let newQuest: Quest = model.newQuest({
            ...quest,
            ...perms,
        })

        return newQuest
    },

    getParentQuestId: (quest: Quest): string => {
        return quest?.parent?.quest_id
    },

    getChildQuestIds: (quest: Quest): string[] => {
        return quest?.sorted_answers
            ?.map(a => a?.user_id !== gon.SYSTEM_USER && a?.child_quests?.[0]?.id)
            .filter(id => id)
    },

    getChildQuests: async (
        questId: string,
        opts: { uncached?: boolean; includeTrunk?: boolean; includeParent?: boolean } = {},
    ) => {
        const BATCH_SIZE = 20
        const quest = getCachedQuest(questId) as Quest
        let filteredQuestIds = model.getChildQuestIds(quest)
        if (opts.includeTrunk) filteredQuestIds.push(questId)
        if (opts.includeParent) filteredQuestIds.push(model.getParentQuestId(quest))

        if (opts.uncached)
            filteredQuestIds = filteredQuestIds.filter(id => !!id && !getCachedQuest(id))

        if (filteredQuestIds?.length > 0) {
            let quests = []

            await Promise.all(
                filteredQuestIds.map(async (_, i) => {
                    if (i % BATCH_SIZE === 0) {
                        const batchIds = filteredQuestIds.slice(i, i + BATCH_SIZE)
                        api.getQuests({ ids: batchIds }).then(apiQuests => {
                            if (apiQuests?.length > 0) {
                                apiQuests.forEach(quest => {
                                    cacheQuest(quest)
                                    quests = quests.concat(apiQuests)
                                })
                            }
                            return apiQuests
                        })
                    }
                }),
            )

            return quests
        } else return []
    },
}

export default model
