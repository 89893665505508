import React, { useState, useEffect, useRef } from 'react'
import styles from 'components/votes/lock-vote.module.scss'
import cn from 'classnames'
import { Answer } from 'types/answers'
import { BITCOIN_UNIT, SATOSHI_UNIT, toBitcoin, toSats, format } from 'lib/bsv-util'
import useStore from 'state/knovStore'
import SatsIcon from 'components/shared/SatsIcon'
import { cacheAnswer } from 'state/cache'
import api from 'api/api'

export default function LockVote({
    answer,
    postLockRef,
}: {
    answer: Answer
    postLockRef: React.RefObject<HTMLDivElement>
}) {
    const currentUserBsvBalance = useStore(state => state.currentUserBsvBalance)
    const currentUserBsvAddress = useStore(state => state.currentUserBsvAddress)
    const openWalletModal = useStore(state => state.actions.modalActions.openWalletModal)

    const quickSats = useStore(state => state.quickBsvLockSats)
    const quickBlocks = useStore(state => state.quickBsvLockBlocks)

    const userBsvUnit = useStore(state => state.bsvUnit)
    const wallet = useStore(state => state.currentUserBsvWallet)

    const [showBsvModal, setShowBsvModal] = useState(false)
    const [customSats, setCustomSats] = useState<number | ''>(quickSats)
    const [customBlocks, setCustomBlocks] = useState<number | ''>(quickBlocks)

    const [customBsvUnit, setCustomBsvUnit] = useState(userBsvUnit)

    const quickLockRef = React.useRef(null)

    const animateLock = (ref, scale = 1.5) => {
        const duration = 100
        const comp = ref.current
        comp.style.transition = `all ${duration / 1000}s ease-in-out`
        //comp.style.backgroundColor = '#ffab46'
        //comp.style.color = 'white'
        comp.style.transform = `scale(${scale})`
        new Promise(resolve => setTimeout(resolve, duration))
            .then(() => {
                comp.style.backgroundColor = ''
                comp.style.color = ''
                comp.style.transform = ''
                return new Promise(resolve => setTimeout(resolve, duration))
            })
            .then(() => {
                comp.style.transition = ''
            })
    }

    const lockCoins = async (sats: number, blocks: number) => {
        try {
            if (currentUserBsvBalance < sats) {
                alert('Not enough coins to lock. Please deposit more coins.')
                openWalletModal()
                return
            }

            const lockTx = await api.createLockTx(
                currentUserBsvAddress,
                answer.bsv_tx_id,
                sats,
                blocks,
            )
            const lockTxId = await wallet.broadcastTx(lockTx, true)
            // console.log("lock res: ", lockTxId)
            const apiAnswer = await api.lockAnswer(answer.id, sats, blocks, lockTxId)
            cacheAnswer(apiAnswer)
        } catch (err) {
            alert(`Error locking coins: ${err}`)
        }
    }

    const isFirstRender = useRef(true)
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return
        }
        animateLock(postLockRef)
    }, [answer.bsv_lock_amt])

    const useQuickSats = userBsvUnit === SATOSHI_UNIT
    const useCustomSats = customBsvUnit === SATOSHI_UNIT
    const activeBitcoinStyles = showBsvModal ? styles.activeBitcoin : ''
    const activeSatsStyles = showBsvModal ? styles.activeSats : ''
    const zeroStyles = answer.bsv_lock_amt ? '' : styles.zero

    return (
        <div className={styles.lockVoteRelativeContainer}>
            <div className={cn(styles.lockVoteComp, zeroStyles)}>
                <div
                    ref={quickLockRef}
                    className={cn(
                        styles.quickLockButton,
                        useQuickSats ? styles.satsButtonColor : styles.bitcoinButtonColor,
                    )}
                    onClick={ev => {
                        ev.stopPropagation()
                        ev.preventDefault()

                        lockCoins(quickSats, quickBlocks)
                        animateLock(quickLockRef, 1.1)
                    }}
                >
                    <span
                        className={cn(styles.currencySymbol, !useQuickSats && styles.bitcoinSymbol)}
                    >
                        {useQuickSats ? (
                            <SatsIcon contextStyles={styles.satsIcon} />
                        ) : (
                            <i className="fa fa-btc" />
                        )}
                    </span>
                    <div className={styles.quickBsv}>
                        {useQuickSats ? format(quickSats) : toBitcoin(quickSats)}
                    </div>
                    <span className={styles.blockSymbol}>
                        <i className="fa fa-cube" />
                    </span>
                    <div className={styles.quickBlock}>{quickBlocks}</div>
                </div>

                {showBsvModal ? (
                    <div
                        className={styles.closeButton}
                        onClick={ev => {
                            ev.stopPropagation()
                            ev.preventDefault()
                            setShowBsvModal(false)
                        }}
                    >
                        <i className="fa fa-remove" />
                    </div>
                ) : (
                    <div
                        className={cn(
                            styles.customLockVote,
                            useQuickSats ? styles.customLockSats : styles.customLockBitcoin,
                            useQuickSats ? activeSatsStyles : activeBitcoinStyles,
                        )}
                        onClick={ev => {
                            ev.stopPropagation()
                            ev.preventDefault()
                            setShowBsvModal(true)
                        }}
                    >
                        <i className="fa fa-lock" />
                    </div>
                )}
            </div>

            {showBsvModal && (
                <div className={styles.lockVotePopup} onClick={e => e.stopPropagation()}>
                    <div
                        className={cn(
                            styles.lockHeader,
                            useCustomSats ? styles.lockHeaderSats : styles.lockHeaderBitcoin,
                        )}
                    >
                        {false && <i className="fa fa-lock" />}
                        Lock
                    </div>

                    <div className={styles.unitTabs}>
                        <div
                            className={cn(
                                styles.unitTab,
                                styles.satoshiUnit,
                                useCustomSats && styles.activeSatoshiUnit,
                            )}
                            onClick={ev => {
                                setCustomBsvUnit(SATOSHI_UNIT)
                            }}
                        >
                            SATOSHIS
                        </div>
                        <div
                            className={cn(
                                styles.unitTab,
                                styles.bitcoinUnit,
                                !useCustomSats && styles.activeBitcoinUnit,
                            )}
                            onClick={ev => {
                                setCustomBsvUnit(BITCOIN_UNIT)
                            }}
                        >
                            BITCOINS
                        </div>
                    </div>

                    <div className={styles.bitcoinControl}>
                        <div className={styles.bitcoinLabel}>
                            {useCustomSats ? 'Satoshis' : 'Bitcoin'}
                        </div>

                        <input
                            className={cn(styles.bitcoinInput, styles.amountInput)}
                            type="number"
                            value={
                                typeof customSats === 'number'
                                    ? useCustomSats
                                        ? customSats
                                        : toBitcoin(customSats)
                                    : ''
                            }
                            onChange={ev => {
                                const val = ev.target.value
                                if (val === '') {
                                    setCustomSats('')
                                } else if (useCustomSats) {
                                    const numSats = Number(val)
                                    if (isNaN(numSats) || numSats < 1) setCustomSats('')
                                    else setCustomSats(numSats)
                                } else {
                                    const numBitcoin = Number(val)
                                    if (isNaN(numBitcoin)) setCustomSats('')
                                    else setCustomSats(toSats(numBitcoin))
                                }
                            }}
                        />
                    </div>

                    <div className={styles.bitcoinControl}>
                        <div className={styles.bitcoinLabel}>Blocks</div>

                        <input
                            className={cn(styles.bitcoinInput, styles.blocksInput)}
                            type="number"
                            value={customBlocks}
                            onChange={ev => {
                                const val = ev.target.value
                                if (val === '') {
                                    setCustomBlocks('')
                                } else {
                                    const num: number = Number(val)
                                    if (isNaN(num) || num < 1) setCustomBlocks('')
                                    else setCustomBlocks(num)
                                }
                            }}
                        />
                    </div>

                    <div className={styles.saveQuickLockButtonContainer}>
                        <div
                            className={cn(styles.saveQuickLockButton)}
                            onClick={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                if (
                                    typeof customSats === 'number' &&
                                    customSats >= 1 &&
                                    typeof customBlocks === 'number' &&
                                    customBlocks > 0
                                ) {
                                    useStore.setState({
                                        quickBsvLockSats: customSats,
                                        quickBsvLockBlocks: customBlocks,
                                        bsvUnit: customBsvUnit,
                                    })
                                    api.updateUserSpaceOptions({
                                        quickBsvLockSats: customSats,
                                        quickBsvLockBlocks: customBlocks,
                                        bsvUnit: customBsvUnit,
                                    })
                                    animateLock(quickLockRef)
                                }
                            }}
                        >
                            <span
                                className={cn(
                                    styles.saveQuickLockButtonLabel,
                                    useCustomSats ? styles.satsColor : styles.bitcoinColor,
                                )}
                            >
                                Save Quick Lock
                            </span>
                        </div>
                    </div>

                    <div className={styles.lockButtonContainer}>
                        <div
                            className={cn(
                                styles.lockButton,
                                useCustomSats ? styles.satsButtonColor : styles.bitcoinButtonColor,
                            )}
                            onClick={e => lockCoins(customSats, customBlocks)}
                        >
                            {true && <i className="fa fa-lock" />}
                            {false && <span className={styles.lockButtonLabel}>Lock</span>}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
