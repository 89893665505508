import { useEffect } from 'react'
import CableApp from 'actioncable'
import useStore from 'state/knovStore'
import { BsvWalletType } from 'wallets/wallets'

const msgTypes = {
    USER_BSV_BALANCE_UPDATED: 'update user bsv balance',
    USER_BSV_ADDRESS_UPDATED: 'update user bsv address',
    USER_BSV_TRANSACTION_SENT: 'sent bsv transaction',
    USER_BSV_TRANSACTION_FAIL: 'failed bsv transaction',
}

export default function useUserChannel() {
    const set = useStore.getState().set
    const activeSpaceId = useStore(state => state.activeSpaceId)
    const currentUserBsvWallet = useStore(state => state.currentUserBsvWallet)

    useEffect(() => {
        if (gon.currentUser && currentUserBsvWallet?.type() === BsvWalletType.SHUALLET) {
            const cable = CableApp.cable
            const channel = cable.subscriptions.create(
                { channel: 'UserChannel', userId: gon.currentUser.id },
                {
                    received: data => {
                        if (data.message === 'new space' && data.space_id !== activeSpaceId) {
                            location.reload()
                        }

                        if (data.message === msgTypes.USER_BSV_BALANCE_UPDATED) {
                            //console.log('USER_BSV_BALANCE_UPDATED', data)
                            set({
                                currentUserBsvBalance: data.balance,
                            })
                        }

                        if (data.message === msgTypes.USER_BSV_ADDRESS_UPDATED) {
                            //console.log('USER_BSV_ADDRESS_UPDATED', data)
                            set({
                                currentUserBsvAddress: data.address,
                            })
                        }
                    },
                },
            )

            return () => {
                cable.subscriptions.remove(channel)
            }
        }
    }, [currentUserBsvWallet])
}

function useTxChannel({ successCallback, errorCallback }) {
    // TODO: should these also be disabled when using panda/relay?
    useEffect(() => {
        if (gon.currentUser) {
            const cable = CableApp.cable
            const channel = cable.subscriptions.create(
                { channel: 'UserChannel', userId: gon.currentUser.id },
                {
                    received: data => {
                        if (data.message === msgTypes.USER_BSV_TRANSACTION_SENT) {
                            //console.log('USER_BSV_TRANSACTION_SENT', data)
                            successCallback(data.transactionId)
                        }

                        if (data.message === msgTypes.USER_BSV_TRANSACTION_FAIL) {
                            //console.log('USER_BSV_TRANSACTION_FAIL', data)
                            errorCallback(data.error)
                        }
                    },
                },
            )

            return () => {
                cable.subscriptions.remove(channel)
            }
        }
    }, [])
}

export { useTxChannel }
