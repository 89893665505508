import React from 'react'
import AddImageButton from 'components/answers/AnswerControls/AddImageButton'
import AddFileButton from 'components/answers/AnswerControls/AddFileButton'
import VideoButton from 'components/answers/AnswerControls/VideoButton'
import MobileVideoButton from 'components/answers/AnswerControls/MobileVideoButton'
import RecordScreenButton from 'components/answers/AnswerControls/RecordScreenButton'
import WithTooltip from 'components/shared/WithTooltip'
import styles from 'components/answers/answer-controls.module.scss'
import cn from 'classnames'
import { isMobile } from 'react-device-detect'

export default function Edit(props) {
    const editModeClass = props.editMode ? 'edit-mode' : ''
    const flexClass = props.editMode ? 'flex' : ''

    const onClickMobileVideo = ev => {
        ev.stopPropagation()
    }

    const answerHoverStyles = props.answerHover ? styles.hoverBtn : null
    const mobileSaveStyles = isMobile ? styles.mobileSaveBtn : null
    const mobileCancelStyles = isMobile ? styles.mobileCancelBtn : null
    const mobileEditMediaContainer = isMobile ? styles.mobileEditMediaContainer : null

    return (
        <div
            className={cn(
                styles.answerControl,
                `edit-btn-comp answer-control save-answer-noti ${props.saving} ${flexClass} ${editModeClass}`,
            )}
            data-test-id="edit-answer"
        >
            <div className={cn(styles.controlContainer, 'edit-control-container edit-container')}>
                <EditButton
                    content={props.content}
                    editMode={props.editMode}
                    toggleEdit={props.toggleEdit}
                    popup={props.popup}
                />
            </div>

            {props.editMode && (
                <div className={styles.editMedia}>
                    <div className={cn(styles.editMediaContainer, mobileEditMediaContainer)}>
                        <div
                            className={cn(styles.cancelBtn, answerHoverStyles, mobileCancelStyles)}
                            onClick={props.toggleEdit}
                        >
                            {isMobile ? <i className="fa fa-chevron-left"></i> : <>Cancel</>}
                        </div>
                    </div>

                    <div className={cn(styles.editMediaContainer, mobileEditMediaContainer)}>
                        <AddFileButton
                            btnId={`${props.answer.id}-${props.panel}`}
                            //ref={fileInputRef}
                            fileHandler={props.fileHandler}
                            answer={props.answer}
                            answerHover={props.answerHover}
                        />
                    </div>

                    {isMobile && (
                        <div className={cn(styles.editMediaContainer, mobileEditMediaContainer)}>
                            <AddImageButton
                                btnId={`${props.answer.id}-${props.panel}`}
                                //ref={imgInputRef}
                                imgHandler={props.imgHandler}
                                answer={props.answer}
                                answerHover={props.answerHover}
                            />
                        </div>
                    )}

                    {isMobile ? (
                        <div className={cn(styles.editMediaContainer, mobileEditMediaContainer)}>
                            <MobileVideoButton
                                //ref={this.videoInputRef}
                                btnId={`video-input-for-answer-${props.answer.id}-${props.panel}`}
                                quest={props.quest}
                                onClick={onClickMobileVideo}
                                videoHandler={props.mobileVideoHandler}
                                answerHover={props.answerHover}
                            />
                        </div>
                    ) : (
                        <div className={cn(styles.editMediaContainer, mobileEditMediaContainer)}>
                            <VideoButton
                                quest={props.quest}
                                answer={props.answer}
                                videoHandler={props.videoHandler}
                                answerHover={props.answerHover}
                            />
                        </div>
                    )}

                    {!isMobile && (
                        <div className={cn(styles.editMediaContainer, mobileEditMediaContainer)}>
                            <RecordScreenButton
                                quest={props.quest}
                                answer={props.answer}
                                videoHandler={props.videoHandler}
                                answerHover={props.answerHover}
                            />
                        </div>
                    )}

                    <div className={styles.saveBtnContainer}>
                        <div
                            onClick={props.savePost}
                            className={cn(styles.saveBtn, mobileSaveStyles)}
                        >
                            {isMobile ? <i className="fa fa-save"></i> : <>Save</>}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

function EditButton(props) {
    const tip = props.editMode ? 'Save' : 'Edit'
    const mobileAnswerBtn = isMobile ? styles.mobileAnswerBtn : null

    return (
        <WithTooltip tip={tip}>
            {!props.editMode && (
                <div className={cn(styles.answerBtn, mobileAnswerBtn)} onClick={props.toggleEdit}>
                    <i className="fa fa-edit" />

                    <span className={cn(styles.controlLabel, 'control-label')}>edit</span>
                </div>
            )}

            {props.editMode && (
                <span className={styles.characterCount}>{props.content.length} chars</span>
            )}
        </WithTooltip>
    )
}

export { EditButton }
