import React, { useState, useEffect } from 'react'
import Member from 'components/teams/Member'
import useStore from 'state/knovStore'
import CreatableSelect from 'react-select/creatable'
import api from 'api/api'
import cn from 'classnames'
import SettingsHeader from 'components/shared/settings/SettingsHeader'
import ReactTooltip from 'react-tooltip'
import { isMobile } from 'react-device-detect'
import { getTooltipRef } from 'state/imperativeApis/tooltipApi'
import SpaceIcon from 'components/spaces/SpaceIcon'
import Button from 'components/shared/buttons/Button'
import CopyButton from 'components/shared/CopyButton'

import styles from 'components/spaces/space-settings.module.scss'

const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export default function Space(props) {
    const [space, setSpace] = useState(props.space)

    useEffect(function getSpace() {
        if (!props.space && props.spaceId) {
            api.getSpace(props.spaceId).then(space => {
                setSpace(space)
                useStore.getState().set({ activeSpaceId: space.id })
            })
        }
    }, [])

    const [selectedOptions, setSelectedOptions] = useState([])
    const tooltipRef = getTooltipRef()
    const openSpaceImageUpdateModal = useStore(
        state => state.actions.modalActions.openSpaceImageUpdateModal,
    )

    const [users, setUsers] = useState(null)
    useEffect(
        function getUsersOnMount() {
            if (space)
                api.getUsers({ invite: true, space_id: space.id }).then(users => {
                    setUsers(users)
                })
        },
        [space],
    )

    const [spaceUsers, setSpaceUsers] = useState(null)
    useEffect(
        function getSpaceUsersOnMount() {
            if (space)
                api.getUsers({ space_id: space.id }).then(users => {
                    setSpaceUsers(users)
                })
        },
        [space],
    )

    const options = users
        ?.filter(u => u?.id !== gon?.currentUser?.id)
        .filter(u => u?.id !== gon.SYSTEM_USER)
        .filter(u => !spaceUsers?.map(su => su?.id).includes(u?.id))
        .map(u => ({ value: u?.name, label: u?.name }))

    const handleChange = selectedOption => {
        selectedOption = selectedOption
            ? selectedOption.filter(
                  so =>
                      so.value.match(emailRegex) ||
                      options.findIndex(o => o.value.startsWith(so.value)) > -1,
              )
            : null

        setSelectedOptions(selectedOption)
    }

    const filterOption = (option, inputValue) => {
        const { value } = option
        return value.toLowerCase().startsWith(inputValue.toLowerCase())
    }

    const createMemberInvite = () => {
        setSelectedOptions(null)
        api.createSpaceInvitation(space.id, {
            space_id: space.id,
            emails: selectedOptions.filter(so => so.value.match(emailRegex)).map(so => so.value),
            ids: selectedOptions.map(so => users.find(u => u?.name === so?.value)?.id),
        }).then(apiSpace => {
            setSpace(apiSpace)
        })
    }

    const createStandingInvite = () => {
        api.createSpaceInvitation(space.id, {
            standing: true,
        }).then(apiSpace => {
            setSpace(apiSpace)
        })
    }

    const handleDeleteMember = async userId => {
        const apiSpace = await api.removeFromSpace(space.id, userId)
        setSpace(apiSpace)
    }

    const handleDeleteInvite = invitationId => {
        // TODO move this to api.
        api.deleteSpaceInvitation(invitationId).then(apiSpace => {
            setSpace(apiSpace)
        })
    }

    const standingInvitations = space?.standing_space_invitations || []

    return (
        <div className="team-comp" style={{ position: 'relative' }}>
            <div className={styles.settingsHeaderContainer}>
                {space && (
                    <SettingsHeader
                        header="Space Settings"
                        name={space?.name}
                        IconComponent={
                            space ? (
                                <SpaceIcon space={space} contextStyles={styles.spaceIcon} />
                            ) : null
                        }
                        openImageUpdateModal={() =>
                            openSpaceImageUpdateModal(space, newSpace => setSpace(newSpace))
                        }
                        handleSave={async name => {
                            const apiSpace = await api.updateSpace(space.id, { name })
                            setSpace(apiSpace)
                        }}
                    />
                )}
            </div>

            <div className={styles.standingContainer}>
                <div className={styles.standingHeaderContainer}>
                    <div className={styles.standingHeader}>Standing Invitation:</div>
                    {standingInvitations?.length === 0 && (
                        <div className={styles.buttonContainer}>
                            <Button
                                type="primary"
                                disabled={!!standingInvitations?.length}
                                //icon="fa fa-link"
                                text="Create Link"
                                contextStyles={styles.button}
                                onClick={createStandingInvite}
                            />
                        </div>
                    )}
                </div>

                {standingInvitations.length > 0
                    ? standingInvitations.map(invite => {
                          return (
                              <div className={styles.standingInvite} key={invite.id}>
                                  <div className={styles.standingInviteLink}>
                                      <a href={invite.url}>{invite.token}</a>
                                  </div>

                                  <div className={styles.standingInviteControls}>
                                      <div className={styles.standingInviteDelete}>
                                          <i
                                              className="fa fa-remove"
                                              onClick={() => handleDeleteInvite(invite.id)}
                                          />
                                      </div>

                                      <CopyButton copy={invite.url}>
                                          <Button
                                              type="primary"
                                              text="Copy"
                                              //icon='fa fa-copy'
                                              contextStyles={styles.button}
                                          />
                                      </CopyButton>
                                  </div>
                              </div>
                          )
                      })
                    : 'None'}
            </div>

            <div className={styles.inviteContainer}>
                <div className={styles.memberHeader}>Member invitations:</div>
                <div className={styles.memberInviteContainer}>
                    <div className={styles.dropdownContainer}>
                        <CreatableSelect
                            isMulti
                            value={selectedOptions}
                            onChange={handleChange}
                            placeholder={users ? 'Enter username or email address.' : 'Loading...'}
                            filterOption={filterOption}
                            formatCreateLabel={v =>
                                emailRegex.test(v)
                                    ? `Invite "${v}" by email`
                                    : 'Please enter a valid email address'
                            }
                            options={options}
                        />
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button
                            type="primary"
                            //icon="fa fa-link"
                            text="Invite"
                            contextStyles={styles.button}
                            onClick={createMemberInvite}
                            disabled={!selectedOptions?.length}
                        />
                    </div>
                </div>

                <h5>Pending Invitations:</h5>
                <div className="user-tag-container">
                    {space?.pending_space_invitations?.length > 0
                        ? space.pending_space_invitations.map(inv => {
                              const content = inv.user ? `@${inv.user.name}` : inv.email
                              return (
                                  <Member
                                      key={inv.id}
                                      content={content}
                                      shouldHandleDelete={gon.currentUser?.id === space?.owner_id}
                                      handleDelete={() => handleDeleteInvite(inv.id)}
                                  />
                              )
                          })
                        : 'None'}
                </div>

                <h5>Space Members:</h5>
                <div className="user-tag-container">
                    {spaceUsers?.map(user => {
                        const content = `@${user.name}`
                        return (
                            <Member
                                key={content}
                                content={content}
                                shouldHandleDelete={
                                    gon.currentUser.id === space.owner_id &&
                                    user.id !== space.owner_id
                                }
                                handleDelete={() => handleDeleteMember(user.id)}
                            />
                        )
                    })}
                </div>

                <ReactTooltip
                    ref={tooltip => (tooltipRef.current = tooltip)}
                    effect="solid"
                    place="bottom"
                    delayShow={1000}
                    arrowColor="rgba(0,0,0,0)"
                    globalEventOff={isMobile ? 'touchstart' : undefined}
                />
            </div>

            <div className="invite-btn-container">
                <Button
                    type="default"
                    icon="fa fa-arrow-left"
                    text="Return to space"
                    onClick={
                        // TODO: do something better here
                        () => window.history.back()
                    }
                />
            </div>
        </div>
    )
}
