import React, { useState } from 'react'
import cn from 'classnames'
import styles from 'components/panels/stream-control.module.scss'
import FilterIcon from 'components/filters/FilterIcon'
import useGetQuest from 'refactor/hooks/api/useGetQuest'

export default function StreamControl({
    panel,
    filter,
    showFilterOptions,
    setShowFilterOptions,
    contextStyles,
}) {
    // We need to re-render when quest loads to derive a filter from it when quest loads.
    // When filter is a stream this will be a no-op.
    const quest = useGetQuest(filter?.questId)
    const [hover, setHover] = useState(false)

    return (
        <>
            <div
                className={cn(styles.streamControlComp, contextStyles)}
                onClick={() => setShowFilterOptions(!showFilterOptions)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {showFilterOptions ? (
                    <i className="fa fa-remove" styles={{ marginTop: 2 }} />
                ) : (
                    <>
                        {hover || panel?.empty ? (
                            <i className="fa fa-bars" />
                        ) : (
                            <FilterIcon
                                disable={true}
                                filter={filter}
                                panel={panel}
                                user={gon?.currentUser}
                                contextStyles={cn(styles.filterIcon)}
                                fontIcon={styles.fontIcon}
                                notiIcon={styles.notiIcon}
                                publicIcon={styles.publicIcon}
                                allIcon={styles.allIcon}
                                historyIcon={styles.historyIcon}
                                hodlockerIcon={styles.hodlockerIcon}
                                twetchIcon={styles.twetchIcon}
                                showNumNoti
                            />
                        )}
                    </>
                )}
            </div>
        </>
    )
}
