import React from 'react'
import styles from 'components/shared/buttons/button.module.scss'
import cn from 'classnames'

export default function Button(props) {
    const type = props.type || 'default'
    const icon = props.icon
    const text = props.text

    const typeStyles = type === 'primary' ? styles.primary : styles.default
    const labelStyles = icon ? styles.labelWithIcon : styles.label
    const disabledStyles = props.disabled ? props.disabledStyles || styles.disabled : null

    const onClick = ev => {
        if (props.disabled || !props.onClick || props.inProgress) {
            return
        }

        ev.preventDefault()
        ev.stopPropagation()

        props.onClick()
    }

    return (
        <div
            className={cn(styles.button, typeStyles, props.contextStyles, disabledStyles)}
            onClick={onClick}
        >
            {props.inProgress ? (
                <i className="fa fa-spinner fa-spin" />
            ) : (
                <>
                    {icon && <i className={icon} />}
                    {text && <span className={labelStyles}>{text}</span>}
                </>
            )}
        </div>
    )
}

export {}
