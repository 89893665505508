import React, { useState, useEffect } from 'react'
import AnswerDndWrapper from './AnswerDndWrapper'
import cn from 'classnames'
import { isMobile } from 'react-device-detect'
import { uniqBy } from 'lodash'
import AnswerInView from 'components/answers/AnswerInView'
import styles from 'components/quests/Quest/quest.module.scss'
import questModel from 'components/quests/questModel'

export default props => {
    const quest = props.quest
    const isActive = props.isActive
    const displayAnswers = () => {
        let returnAnswers
        if (isActive) {
            returnAnswers = props.displayAnswers()
        } else if (quest.is_system) {
            // TODO: our current notification system shows the first answer of custom system quests :(
            returnAnswers = [props.displayAnswers()[1]]
        } else if (props.filter?.query && quest?.matching_answer_ids?.length > 0) {
            const headAnswers = props.displayAnswers().slice(0, 1)
            returnAnswers = headAnswers.concat(
                quest.matching_answer_ids?.map(id => props.displayAnswers().find(a => a.id === id)),
            )
        } else {
            const headAnswers = props.displayAnswers().slice(0, 1)
            const tailAnswers = questModel.showUnarchivedAnswers(quest)
            let latestAnswers = tailAnswers.filter(
                a => a?.created_at >= quest.last_action_created_at,
            )
            if (latestAnswers.length === 0) latestAnswers = tailAnswers.slice(-3)
            returnAnswers = headAnswers.concat(latestAnswers)
        }
        return returnAnswers
    }
    const [answers, setAnswers] = useState(displayAnswers())

    useEffect(
        function updateAnswersOnQuestChange() {
            setAnswers(displayAnswers())
        },
        [props.quest, props.answerFilter, props.showAll, props.answersShown],
    )

    const resetAnswers = () => {
        setAnswers(displayAnswers())
    }

    const deleteLocalItem = deleteIndex => {
        setAnswers(answers => {
            let newAnswers = [...answers]
            newAnswers.splice(deleteIndex, 1)[0]
            return newAnswers
        })
    }

    const insertLocalItem = (insertIndex, item) => {
        let newSortedAnswers = [...answers]
        newSortedAnswers.splice(insertIndex, 0, item)
        setAnswers(newSortedAnswers)
    }

    const replaceItemsLocally = (deleteIndex, insertIndex) => {
        let newSortedAnswers = [...answers]
        const deletedItem = newSortedAnswers.splice(deleteIndex, 1)[0]
        newSortedAnswers.splice(insertIndex, 0, deletedItem)
        setAnswers(newSortedAnswers)
    }

    const uniqAnswers = uniqBy(answers, a => a?.id)

    return (
        <div
            className={cn(
                'no-select-answer-container',
                `answers-container ${props.showStatsClass}`,
                styles.answersContainer,
            )}
        >
            {
                // NOTE: the uniqBy below is here because there are brief windows
                // when the UI might have the same answer twice after a user drags
                // between quests which makes react unhappy due to non-unique keys
            }
            {uniqAnswers.map((answer, ix) => {
                // System quests from the knovigator user dont' have parent answers! Still use old question model. TODO: fix this.
                // This means in notifications the first (title) answer is undefined.
                // We filter it out since we hide the title for knovigator quests anyways.

                if (answer /*&& !firstSystemUser*/) {
                    return (
                        <div
                            key={`${answer?.id}_${props.quest.id}`}
                            className={`answer-map-container`}
                            // style={Object.assign({}, provided.draggableProps.style, { transform: transform })}
                        >
                            <div className="answer-container">
                                <AnswerInView
                                    key={answer.id}
                                    answerId={answer.id}
                                    visibleDefault={props.questIx === 0 && ix < 10}
                                    isActive={props.isActive}
                                >
                                    <AnswerDndWrapper
                                        panelId={props.panelId}
                                        isTitle={ix == 0}
                                        isTail={!isActive && ix >= 1}
                                        titleAnswerProps={ix == 0 ? props.titleAnswerProps : null}
                                        style={{ position: 'relative' }}
                                        panel={props.panel}
                                        query={props.filter?.query}
                                        selectable={true}
                                        ix={ix}
                                        isFirst={ix === 1} // [0] is title and we want the first reply.
                                        isLast={ix === uniqAnswers.length - 1}
                                        answerId={answer.id}
                                        //answer={answer}
                                        quest={props.quest}
                                        removeAnswer={props.removeAnswer}
                                        showInsert={props.showInsert}
                                        insertAnswer={props.insertAnswer}
                                        updateAnswer={props.updateAnswer}
                                        showControls={true}
                                        showBranching={props.showBranching}
                                        showEmbedButton={props.showEmbedButton}
                                        isMobile={isMobile}
                                        showAll={props.showAll}
                                        moveAnswer={props.moveAnswer}
                                        moveAnswerBetweenQuests={props.moveAnswerBetweenQuests}
                                        deleteLocalItem={deleteLocalItem}
                                        insertLocalItem={insertLocalItem}
                                        replaceItemsLocally={replaceItemsLocally}
                                        resetAnswers={resetAnswers}
                                        resetOtherQuest={props.resetOtherQuest}
                                        sideQuest={props.sideQuest}
                                        isActive={props.isActive}
                                        cryptoVoteMode={
                                            answer?.crypto_vote_total &&
                                            answer.crypto_vote_total > 0
                                        }
                                        blog={props.blog}
                                        forbidDrop={props.forbidDrop}
                                        showVotes={props.showVotes}
                                        isBsv={props.isBsv}
                                    />
                                </AnswerInView>
                            </div>

                            {ix == 0 && isActive && <>{props.titleAnswerProps?.expandAnswers}</>}
                        </div>
                    )
                }
            })}
        </div>
    )
}
