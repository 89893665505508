import React from 'react'
import styles from 'components/filters/stream-name.module.scss'
import cn from 'classnames'
import useStore from 'state/knovStore'

export default function StreamName(props) {
    const filter = props.filter
    //console.log('stream name filter', props.panel, filter)
    const query = props.query
    const user = gon.currentUser
    const publicUser = useStore(state => state.publicUser)
    const publicTeams = useStore(state => state.publicTeams)
    const users = useStore(state => state.users)
    const knovigatorSpace = useStore(state => state.knovigatorSpace)
    //let filterUser = useStore(state => state.filterUser)
    const filterUser = filter?.user ? users?.find(u => u.id === filter.user) : null
    const activeSpaceId = useStore(state => state.activeSpaceId)
    const userSpaces = useStore(state => state.userSpaces)

    let streamName
    if (publicUser) {
        streamName = publicUser.name
    } else if (filter && filter.user) {
        streamName = filterUser ? filterUser.name : 'Loading...'
    } else if (filter && filter.all) {
        streamName = 'All'
    } else if (user && filter && filter.team_ids && filter.team_ids.length > 0) {
        //console.log('filter', filter)
        let team
        // Splatting user and public teams may reslult in duplicate teams but find should get the first match.
        team =
            user.space_teams &&
            [...user.space_teams, ...(publicTeams || [])].find(t => filter.team_ids[0] === t.id)
        streamName = team && team.name
    } else if (filter && filter.notifications) {
        streamName = 'Notifications'
    } else if (filter && filter.private) {
        streamName = 'Private'
    } else if (filter?.link) {
        streamName = 'Link Access'
    } else if (filter?.clip) {
        streamName = 'Clips'
    } else if (filter?.public_clip) {
        streamName = 'Public Clips'
    } else if (filter?.public) {
        streamName = 'Public'
    } else if (filter?.hodlocker) {
        streamName = 'Hodlocker'
    } else if (filter?.twetch) {
        streamName = 'Twetch'
    } else if (filter?.treechat) {
        streamName = 'Treechat'
    } else if (filter?.history) {
        streamName = 'History'
    } else if (filter?.starred) {
        streamName = 'Starred'
    } else if (filter?.people) {
        streamName = 'People'
    } else if (filter?.participants) {
        streamName = 'People'
    } else {
        streamName = gon.currentUser
            ? userSpaces.find(s => s.id === activeSpaceId)?.name
            : knovigatorSpace?.name
    }

    const showQuery = props.searchBar
    const nameSearchStyles = showQuery ? styles.nameAndSearch : null
    const showStreamName = !showQuery
    const panelStyles = props.panel === 'right' ? styles.rightPanel : null

    const memeStreamStyles = props.filter?.meme ? styles.memeStream : null

    return (
        <div className={styles.streamNameComp}>
            {showStreamName && (
                <div className={styles.streamNameContainer}>
                    <div
                        className={cn(
                            styles.streamName,
                            nameSearchStyles,
                            memeStreamStyles,
                            props.contextStyles,
                        )}
                    >
                        {streamName}
                    </div>
                </div>
            )}

            {showQuery && (
                <div className={styles.queryContainer}>
                    <div className={cn(styles.queryIcon, panelStyles)}>
                        <i className="fa fa-search" />
                    </div>

                    <div className={styles.query}>{query}</div>
                </div>
            )}
        </div>
    )
}
