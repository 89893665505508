import { useInfiniteQuery } from '@tanstack/react-query'
import api from 'api/api'
import { Quest } from 'types/quests'
import { cacheQuest, getCachedQuest } from 'state/cache'

type ResponseData = {
    count: number
    pinnedQuest: Quest | null
    quests: Quest[]
}

export const serializeFilterKey = (filter: object) => {
    return filter
        ? JSON.stringify(
              Object.keys(filter)
                  .sort()
                  .reduce((result, key) => {
                      result[key] = filter[key]
                      return result
                  }, {}),
          )
        : null
}

export default function useGetQuests(
    filter: object,
    options: { enabled?: boolean; params?: object } = { enabled: false, params: {} },
) {
    const emptyResult = {
        quests: [],
        totalQuests: 0,
        pinnedQuest: null,
    }

    const shouldFetch = options.enabled && filter && !filter.questId

    const filterKey = serializeFilterKey(filter)
    //console.log('useGetQuests FILTER KEY', filterKey)

    const result = useInfiniteQuery({
        queryKey: ['quests', filterKey],
        queryFn: async ({ pageParam = 1 }) => {
            const data = await api.getQuests({ ...filter, ...options.params, page: pageParam })
            if (data.quests) {
                data.quests.forEach(quest => {
                    cacheQuest(quest)
                })
            }
            return { ...data, quests: data.quests.map(quest => ({ id: quest.id })) }
        },

        enabled: shouldFetch,
        staleTime: Infinity,
        gcTime: Infinity, // Don't cache quests as a group.
        retry: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        structuralSharing: false,
        networkMode: 'always',

        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => {
            const fetchedAmount = pages.reduce((acc, page) => acc + page.quests.length, 0)
            return fetchedAmount < lastPage.count ? pages.length + 1 : undefined
        },
    })

    // This lets us call the hook no matter what from Panel to ensure hooks are called in the same order every time without illegal conditional hooks or skipping useInfiniteQuery.
    // The right way to do this is to split the panels out and have them call the relevant hook, eg QuestPanel and QuestsPanel.
    if (!shouldFetch) {
        return emptyResult
    }

    const quests =
        result.data?.pages.flatMap(page => page.quests.map(quest => getCachedQuest(quest.id))) || []

    return {
        ...result,
        quests,
        totalQuests: result.data?.pages[0]?.count || 0,
        pinnedQuest: result.data?.pages[0]?.pinnedQuest || null,
    }
}
