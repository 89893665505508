import { useEffect, useRef } from 'react'
import * as cache from 'state/cache'
import CableApp from 'actioncable'
import useStore from 'state/knovStore'

const REPORT = false

// TODO: have this map be stored elsewhere and imported so that all
// components can use it
const msgTypes = {
    UPDATE_QUEST: 'update quest',
    SET_CHATGPT_PENDING: 'set chatgpt pending',
    SET_CHATGPT_COMPLETE: 'set chatgpt complete',
    SET_CHATGPT_ERROR: 'set chatgpt error',
}

export default function useQuestChannel(questId: string) {
    const set = useStore.getState().set

    const channel = useRef<any>(null)

    const subscribe = () => {
        channel.current = CableApp.cable.subscriptions.create(
            { channel: 'QuestChannel', stream_from: `quest_${questId}`, quest_id: questId },
            {
                received: async data => {
                    if (data.message === msgTypes.UPDATE_QUEST) {
                        let updatedQuest = data.quest
                        // Only cache if we have seen the quest before.
                        if (cache.getCachedQuest(updatedQuest.id)) cache.cacheQuest(updatedQuest)
                    }

                    if (
                        data.message === msgTypes.SET_CHATGPT_PENDING ||
                        data.message === msgTypes.SET_CHATGPT_ERROR
                    ) {
                        const { questId } = data
                        const status =
                            data.message === msgTypes.SET_CHATGPT_PENDING ? 'pending' : 'error'

                        set(state => {
                            state.knovBotPendingStatus[questId] = status
                        })
                    }

                    if (data.message === msgTypes.SET_CHATGPT_COMPLETE) {
                        const { questId } = data
                        // deleting the quest id key from the object
                        set(state => {
                            delete state.knovBotPendingStatus[questId]
                        })
                    }
                },

                connected() {
                    if (REPORT && gon.env === 'development')
                        console.log(`Connected to QuestChannel id ${questId}.`)
                },

                disconnected() {
                    if (REPORT && gon.env === 'development')
                        console.log(`Disconnected from QuestChannel id ${questId}.`)
                },
            },
        )
        if (window.log && gon.env === 'development')
            console.log(
                `subscribed to quest channel id ${questId}`,
                channel.current,
                channel.current?.consumer?.subscriptions?.subscriptions?.length,
            )
    }

    const unsubscribe = () => {
        if (channel.current) {
            channel.current.unsubscribe()
            if (window.log && gon.env === 'development')
                console.log(
                    `unsubscribed from quest channel id ${questId}`,
                    channel.current,
                    channel.current?.consumer?.subscriptions?.subscriptions?.length,
                )
            channel.current = null
        }
    }

    useEffect(() => {
        if (questId) subscribe()

        return () => unsubscribe()
    }, [questId])

    return { subscribe, unsubscribe }
}
