import React, { useRef, useLayoutEffect, useCallback, ReactNode } from 'react'
import { PanelContextProvider } from 'refactor/hooks/usePanelContext'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'
import styles from 'components/panels/panel-container.module.scss'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { SMALL, BIG, transformTo, getTransform } from 'lib/transform'
import useStore from 'state/knovStore'

interface PanelContainerProps {
    panel: any // Specify a more accurate type if possible
    index: number
    hide: boolean
    children: ReactNode
    isEmpty: boolean
    animationPanel: boolean
    animationStyles: {
        mirrorPanelRef: HTMLDivElement
        transform: string
        scrollTop: number
    }
}

export default React.memo(function PanelContainer({
    panel,
    index,
    hide,
    children,
    isEmpty,
    animationPanel,
    animationStyles,
}: PanelContainerProps) {
    console.log('RENDER PANEL CONTAINER', index, panel.panelId, panel.filter)
    const activeIndex = useStore(state => state.activeIndex)
    const panelsLength = useStore(state => state.panels.state.length)
    const panelContainerRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(function initAnimationPanelTransform() {
        if (!isMobile && animationPanel && animationStyles && panelContainerRef.current) {
            const transformContainer =
                panelContainerRef.current?.querySelector('.transform-container')
            const scrollContainer = panelContainerRef.current?.querySelector('.scroll-container')

            if (transformContainer) transformContainer.style.transform = animationStyles.transform
            if (scrollContainer) scrollContainer.scrollTop = animationStyles.scrollTop
        }
    }, [])

    // Transform scenarios.
    // User swipes, this is handled in PanelController.
    // Inserting a panel, handled below.
    // Removing a panel, handled below.
    // During a swipe, we dont need to run the below.

    useLayoutEffect(function performTransform() {
        if (isMobile || isEmpty || animationPanel || !panelContainerRef.current) return

        const transformContainer = panelContainerRef.current?.querySelector('.transform-container')
        const scrollContainer = panelContainerRef.current?.querySelector('.scroll-container')
        const panelComp = panelContainerRef.current?.querySelector('.panel-comp')
        if (!transformContainer || !scrollContainer || !panelComp) return

        const centerIndex = getCenterIndex()
        //const matrix = new DOMMatrix(getComputedStyle(transformContainer).transform)
        //const currentScale = matrix.a
        // This is already scaled to the transform scale if transform: scale translate.
        //const currentTranslateY = matrix.f

        // We use the css values instead of the matrix bc these update instantly, while the matrix has to wait for a repaint, and bc parts of the code are async we want to snapshot the desired values before any callbacks run.
        const transformObject = getTransform(transformContainer.style.transform)
        const currentScale = transformObject.scale || 1

        console.log(
            'performTransform',
            panel.panelId,
            index,
            centerIndex,
            currentScale,
            panel.filter,
        )

        if (centerIndex === index && currentScale === SMALL) {
            transformTo(BIG, panel)
        } else if (centerIndex !== index && currentScale === BIG) {
            transformTo(SMALL, panel)
        }
    }, [])

    const lastScrollTopRef = useRef(0)

    const onPanelScroll = useCallback((ev: React.UIEvent<HTMLDivElement>) => {
        if (window.isPanelScrolling) clearTimeout(window.isPanelScrolling)
        window.isPanelScrolling = setTimeout(() => {
            window.isPanelScrolling = null
        }, 100)

        const scrollContainer = ev.currentTarget
        const transformContainer = scrollContainer.querySelector(
            '.transform-container',
        ) as HTMLElement
        if (!transformContainer) return

        const { scrollTop } = scrollContainer
        const { top: containerTop } = transformContainer.getBoundingClientRect()
        const currentTransform = new DOMMatrix(getComputedStyle(transformContainer).transform)
        const currentTranslateY = currentTransform.m42
        const currentScale = currentTransform.a

        // Tolerance to prevent jitters (e.g., 1 pixel)
        const TOLERANCE = 1

        const adjustPosition = () => {
            // Only proceed if there's a non-zero translation
            if (Math.abs(currentTranslateY) > TOLERANCE) {
                let adjustment = 0
                let newScrollTop = scrollTop

                if (scrollTop === 0 && containerTop < -TOLERANCE) {
                    // Content is translated up, but we've scrolled to the top
                    adjustment = Math.abs(containerTop)
                    newScrollTop = adjustment
                } else if (scrollTop > 0 && containerTop > TOLERANCE) {
                    // Content is translated down, but we've scrolled away from the top
                    adjustment = Math.min(scrollTop, containerTop)
                    newScrollTop = scrollTop - adjustment
                }

                if (adjustment > 0) {
                    const scrollSpeed = Math.abs(scrollTop - lastScrollTopRef.current)
                    lastScrollTopRef.current = scrollTop

                    const transitionDuration = Math.max(100, Math.min(300, 300 - scrollSpeed * 10))
                    const easing = 'ease-out'

                    transformContainer.style.transition = `transform ${transitionDuration}ms ${easing}`
                    transformContainer.style.transform = `translateY(0px) scale(${currentScale})`

                    setTimeout(() => {
                        transformContainer.style.transition = ''
                        scrollContainer.scrollTop = newScrollTop
                    }, transitionDuration)
                }
            }
        }

        requestAnimationFrame(adjustPosition)
    }, [])

    const getPanelRef = panel.getPanelRef
    const setPanelRef = panel.setPanelRef

    const scrollContainerRef = useRef<HTMLDivElement>(null)
    //if (!animationPanel) console.log('>>>> PANEL CONTAINER scroll container ref', panel.panelId, scrollContainerRef)

    const staticPanelContext = React.useMemo(() => ({ panelId: panel.panelId }), [panel.panelId])
    const panelContext = React.useMemo(
        () => ({
            panel,
            panelId: panel.panelId,
            filter: panel.filter,
            hide,
            panelContainerRef,
            scrollContainerRef,
            animationPanel,
            animationStyles,
        }),
        [panel.panelId, panel.filter, hide],
    )

    return (
        <PanelContextProvider value={panelContext}>
            <div
                style={{ visibility: hide ? 'hidden' : 'visible' }}
                className={cn(
                    isMobile && 'is-mobile',
                    styles.panelContainer,
                    'panel-container-comp',
                )}
                ref={useCallback(ref => {
                    //console.log('panel ref empty:', panel.empty,  'animation:', !!animationPanel,panel.panelId, panel.filter, panel)
                    if (!ref) {
                        return
                    }

                    panelContainerRef.current = ref
                    const panelRef = getPanelRef()
                    if (animationPanel && panelRef) {
                        ref.scrollTop = panelRef.scrollTop
                    } else if (panelRef !== ref) {
                        setPanelRef(ref)
                        const prevScrollTop = panelRef?.scrollTop
                        if (prevScrollTop) {
                            ref.scrollTop = prevScrollTop
                        }
                    }
                }, [])}
            >
                <div
                    ref={scrollContainerRef}
                    className={cn(
                        'scroll-container', // Deterministic class for dom selection.
                        styles.scrollContainer,
                        'h100',
                    )}
                    onScroll={onPanelScroll}
                >
                    <div
                        className={cn(
                            styles.transformContainer,
                            `panel-${panel.panelId}`,
                            'transform-container h100',
                            // PanelState contains the 'center-panel' class for the initially centered panel.
                            // For easier identification in dom.
                            animationPanel && 'animation-panel',
                            // This is set imperatively via cssAnimations.js as swipes happen, but update declaratively here for animation panels for correct sizing during transitions.
                            //getCenterIndex() === index && 'center-panel',
                            animationPanel && getCenterIndex() === index && 'center-panel',
                            isMobile && 'is-mobile',
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </PanelContextProvider>
    )
})
