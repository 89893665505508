import React from 'react'
import { once } from 'lodash'

const scrollRefs = {
    middleScrollContainerRef: React.createRef(),
    rightScrollContainerRef: React.createRef(),
    selectedAnswerOrQuoteRef: React.createRef(),
    rightPanelSavedScrollValues: {
        answerFromTopRef: React.createRef(),
    },
}

export function assignMiddleScrollContainerRef(ref) {
    scrollRefs.middleScrollContainerRef.current = ref
}

export function assignRightScrollContainerRef(ref) {
    scrollRefs.rightScrollContainerRef.current = ref
}

export function assignSelectedAnswerOrQuoteRef(ref) {
    scrollRefs.selectedAnswerOrQuoteRef.current = ref
}

// TODO - always use this function to initiate scrolling everywhere in the app so it is easy to debug and
//  track who is initiating scrolling
export function scroll(scrollContainerRef, newPosition, animateOptions) {
    const newPositionProvided = newPosition !== null && newPosition !== undefined
    if (newPositionProvided && animateOptions) {
        scrollContainerRef?.current?.scrollTo({
            top: newPosition,
            ...animateOptions,
        })
    } else if (newPositionProvided) {
        scrollContainerRef.current.scrollTop = newPosition
    } else {
        scrollContainerRef.current.scrollIntoView(animateOptions)
    }
}

function _withinOnePixel(value1, value2) {
    return (
        value1 === value2 ||
        (value1 > value2 && value1 <= value2 + 1) ||
        (value1 < value2 && value1 >= value2 - 1)
    )
}

function _scrollCompleteCallback(scrollContainer, finalPositionOrElement, callback) {
    const callbackOnce = once(callback)
    const scrollListener = () => {
        if (typeof finalPositionOrElement === 'number') {
            const scrollTop = scrollContainer.current.scrollTop
            const scrollHeight = scrollContainer.current.scrollHeight
            const containerHeight = scrollContainer.current.getBoundingClientRect().height
            if (
                _withinOnePixel(scrollTop, finalPositionOrElement) ||
                _withinOnePixel(scrollTop + containerHeight, scrollHeight)
            ) {
                scrollContainer.current.removeEventListener('scroll', scrollListener)
                callbackOnce()
            }
        } else {
            const fromTop = Math.floor(finalPositionOrElement.current.getBoundingClientRect().top)
            const scrollTop = scrollContainer.current.scrollTop
            const scrollHeight = scrollContainer.current.scrollHeight
            const containerHeight = scrollContainer.current.getBoundingClientRect().height
            if (
                _withinOnePixel(fromTop, 0) ||
                _withinOnePixel(scrollTop, scrollHeight - containerHeight)
            ) {
                scrollContainer.current.removeEventListener('scroll', scrollListener)
                callbackOnce()
            }
        }
    }

    scrollContainer.current.addEventListener('scroll', scrollListener)

    // Set a timeout to ensure the listener is removed even if the scroll doesn't complete
    const timeoutId = setTimeout(() => {
        scrollContainer.current.removeEventListener('scroll', scrollListener)
        callbackOnce()
    }, 600)
}

function _scrollToQuest(scrollContainer, fromTop, onComplete) {
    const headerSpace = 48 // questOffset questHeader scrollOffset
    const currentScrollTop = scrollContainer.current.scrollTop
    const newScrollTop = Math.floor(currentScrollTop + fromTop - headerSpace)

    if (fromTop <= headerSpace) {
        onComplete()
    } else {
        _scrollCompleteCallback(scrollContainer, newScrollTop, onComplete)
        scroll(scrollContainer, newScrollTop, { behavior: 'smooth' })
    }

    /*
    if (fromTop > headerSpace) {
        const current = scrollContainer.current
        if (current) {
            current.style.transition = 'transform 0.3s linear'
            current.style.transform = `translate(0,-${fromTop - headerSpace}px)`
        }
    }
    */
}

export function scrollMiddlePanel(newPosition, animateOptions) {
    const currentScrollPosition = scrollRefs.middleScrollContainerRef?.current?.scrollTop
    scroll(
        scrollRefs.middleScrollContainerRef,
        typeof newPosition === 'function' ? newPosition(currentScrollPosition) : newPosition,
        animateOptions,
    )
}

export function scrollRightPanel(newPosition, animateOptions) {
    if (scrollRefs.rightScrollContainerRef.current) {
        const currentScrollPosition = scrollRefs.rightScrollContainerRef.current.scrollTop
        scroll(
            scrollRefs.rightScrollContainerRef,
            typeof newPosition === 'function' ? newPosition(currentScrollPosition) : newPosition,
            animateOptions,
        )
    }
}

export function rightPanelScrollToSelectedQuote(selectedQuoteRef, callback) {
    const fromTop = selectedQuoteRef.current.getBoundingClientRect().top
    const currentPosition = scrollRefs.rightScrollContainerRef.current.scrollTop
    const newPosition = Math.floor(currentPosition + fromTop - 200)
    _scrollCompleteCallback(scrollRefs.rightScrollContainerRef, newPosition, callback)
    scrollRightPanel(newPosition, { behavior: 'smooth' })
}

export function middlePanelScrollToQuest(fromTop, onComplete) {
    _scrollToQuest(scrollRefs.middleScrollContainerRef, fromTop, onComplete)
}

export function rightPanelScrollToQuest(fromTop, onComplete) {
    _scrollToQuest(scrollRefs.rightScrollContainerRef, fromTop, onComplete)
}

export const preserveScrollOnSlide = {
    saveRightPanelScrollState: fromTop => {
        const { answerFromTopRef } = scrollRefs.rightPanelSavedScrollValues
        answerFromTopRef.current = fromTop
    },
    copyRightScrollStateToMiddle: () => {
        const { middleScrollContainerRef, rightPanelSavedScrollValues, selectedAnswerOrQuoteRef } =
            scrollRefs
        const { answerFromTopRef } = rightPanelSavedScrollValues

        if (
            selectedAnswerOrQuoteRef.current &&
            middleScrollContainerRef.current &&
            answerFromTopRef.current
        ) {
            requestAnimationFrame(() => {
                scroll(selectedAnswerOrQuoteRef)
                const currentScrollTop = middleScrollContainerRef.current.scrollTop
                const currentFromTop = selectedAnswerOrQuoteRef.current.getBoundingClientRect().top
                const savedFromTop = answerFromTopRef.current
                const difference = savedFromTop - currentFromTop

                if (difference) {
                    scrollMiddlePanel(currentScrollTop - difference)
                }

                // reset refs
                answerFromTopRef.current = null
            })
        }
    },
}

export function scrollToSelectedAnswer(options) {
    if (
        scrollRefs.selectedAnswerOrQuoteRef.current &&
        scrollRefs.middleScrollContainerRef.current
    ) {
        const boundingRect = scrollRefs.selectedAnswerOrQuoteRef.current.getBoundingClientRect()
        const fromTop = boundingRect.top
        const answerHeight = boundingRect.height
        const containerHeight =
            scrollRefs.middleScrollContainerRef.current.getBoundingClientRect().height
        if (fromTop + answerHeight > containerHeight || fromTop < 0) {
            scrollMiddlePanel(
                currentPosition => currentPosition + fromTop - 200,
                options || { behavior: 'smooth' },
            )
        }
    }
}
